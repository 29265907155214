import {StoreChannelComplete} from '../types'
import {HostPartialType} from '@/features/authentication/types'
import {GoalStatusesE, GoalType} from '@/features/goal/types'
import {ALL_LINKS} from '@/utilities/constants/regex'
import dayjs from 'dayjs'

export enum SubscriptionStatusesE {
    ACTIVE = 'active',
    CANCELED = 'canceled',
    REACTIVABLE = 'reactivable',
    PAUSED = 'paused',
    FAILED = 'failed',
    FREE = 'free',
    INACTIVE = 'inactive'
}

export enum SubscriptionTypesE {
    SUBSCRIPTION = 'subscription',
    REFERRAL = 'referral',
    FREE_CONNECTION = 'free_connection'
}

export const getSubscriptionStatus = (channelDetails: StoreChannelComplete | null) => {
    if (!channelDetails) return
    const subStatus = channelDetails.status ?? channelDetails.subscription_status
    const type = channelDetails.type

    // FREE AND PAUSED
    // type:  free_connection
    // subscription_status: null
    // is_paused: true
    /*TODO: verify logic in backend*/
    if (
        (type === SubscriptionTypesE.FREE_CONNECTION ||
            type === SubscriptionTypesE.REFERRAL ||
            type === SubscriptionTypesE.SUBSCRIPTION) &&
        !subStatus &&
        channelDetails.is_paused
    ) {
        return SubscriptionStatusesE.PAUSED
    }
    // OPEN & FREE
    // type:  free_connection
    // subscription_status: null
    // valid_until_at: null
    if (type === SubscriptionTypesE.FREE_CONNECTION && !subStatus) {
        return SubscriptionStatusesE.FREE
    }

    // OPEN & FREE - Referred
    // type:  referral
    // subscription_status: null
    // valid_until_at: null
    if (type === SubscriptionTypesE.REFERRAL && !subStatus) {
        return SubscriptionStatusesE.FREE
    }

    // OPEN BY A GOAL
    // type:  subscription
    // subscription_status: null
    // valid_until_at: null
    if (type === SubscriptionTypesE.SUBSCRIPTION && !subStatus) {
        return SubscriptionStatusesE.FREE
    }

    // FAILED REBILL
    // type:  subscription
    // subscription_status: 'failed'
    if (type === SubscriptionTypesE.SUBSCRIPTION && subStatus === SubscriptionStatusesE.FAILED) {
        return SubscriptionStatusesE.FREE
    }

    // PAID  (WAS FREE)
    // type:  free_connection
    // subscription_status: "active"
    // valid_until_at: null
    if (subStatus === SubscriptionStatusesE.ACTIVE && !channelDetails.valid_until_at) {
        return SubscriptionStatusesE.ACTIVE
    }
    // CANCELED FROM PAID (WAS FREE)
    // type:  free_connection
    // subscription_status: "canceled"
    // valid_until_at: 2022-09-04 09:40:47
    // NOTE: we are assuming that `valid_until_at` is UTC 0
    if (subStatus !== 'active' && channelDetails.valid_until_at) {
        const isReactivable =
            dayjs.utc(dayjs(channelDetails.valid_until_at).format('YYYY-MM-DD hh:mm:ss')).diff(dayjs(), 'seconds') > 0
        if (isReactivable) {
            // ( is reactivable )
            return SubscriptionStatusesE.REACTIVABLE
        }
        return SubscriptionStatusesE.CANCELED
    }

    return null
}

export const getActiveGoalByUserRole = (user: HostPartialType) => {
    // Check if user has an active goal
    const goalsList = user?.type_attributes?.goals ? user?.type_attributes?.goals : []

    const activeGoal = goalsList?.find(goal => goal.status === GoalStatusesE.ACTIVE)

    return activeGoal as GoalType
}

export const checkIfMessageIncludeAllLink = (message: string) => {
    return message?.search(ALL_LINKS) !== -1
}
