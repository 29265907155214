import {useMe} from '@/features/authentication/queries/useMe.ts'

import {AxiosError} from 'axios'
import {Navigate, Outlet, generatePath, useSearchParams} from 'react-router-dom'
import * as routes from '@/utilities/constants/routeNames'
import {SessionStorageManager} from '@/utilities/sessionStorage'

import {LocalStorageManager} from '@/utilities/localStorage'
import {UserRoleE} from '@/types'

export const PublicRoute = () => {
    const {loggedIn, isError, data: user, error} = useMe(false)
    const [searchParams] = useSearchParams()
    const goalReferral = searchParams.get('goal')

    const getCorrectRedirectPath = () => {
        const isGuest = user?.type === UserRoleE.Guest
        const isHost = user?.type === UserRoleE.Host
        const isRepresentative = user?.type === UserRoleE.Representative
        const isAgent = user?.type === UserRoleE.Agent
        const isFirstLogin = !!SessionStorageManager.checkIsFirstLogin.get()
        const AgentAndRepsInvitationToken = LocalStorageManager.invitationRepsAndAgentToken.get()

        switch (true) {
            case isGuest && !!goalReferral:
                return routes.ROUTE_GOAL.replace(':slug', goalReferral ?? '')
            case isGuest && isFirstLogin:
                return routes.ROUTE_ON_BOARD_TOUR
            case isGuest:
                return routes.ROUTE_HOME
            case isHost && isFirstLogin:
                return routes.ROUTE_WELCOME_HOST
            case isHost:
                return routes.ROUTE_HOST_MY_PROFILE
            case (isAgent || isRepresentative) && !!AgentAndRepsInvitationToken:
                return generatePath(routes.ROUTE_ACCEPT_JOIN_REQUEST, {token: AgentAndRepsInvitationToken})
            case isRepresentative || isAgent:
                return routes.ROUTE_MESSAGE_CENTER
            default:
                return routes.ROUTE_WHAT_IS_MORGI
        }
    }

    if (isError) {
        const allowableStatusError = [401]
        const errorStatus = (error as AxiosError)?.response?.status as number
        if (!allowableStatusError.includes(errorStatus)) throw error
    }

    return loggedIn ? <Navigate to={getCorrectRedirectPath()} /> : <Outlet />
}
