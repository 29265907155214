import styled, {css} from 'styled-components'

export const StyledWrapper = styled.div<{$heightOfHeader?: number; isOpenModals: boolean}>`
    ${({isOpenModals, $heightOfHeader, theme: {spacing, zIndex, sizing, mediaQueries}}) => css`
        pointer-events: none;
        position: fixed;
        top: ${$heightOfHeader
            ? `${$heightOfHeader + spacing * 3}px`
            : `calc(${spacing * 3}px + ${sizing.headerMobileHeight})`};
        left: ${spacing}px;
        right: ${spacing}px;
        z-index: ${isOpenModals ? 1034 : zIndex.profileAlert};
        ${mediaQueries.m} {
            top: ${$heightOfHeader
                ? `${$heightOfHeader + spacing * 3}px`
                : `calc(${spacing * 3}px + ${sizing.headerDesktopHeight})`};
        }
    `}
`

export const StyledBlockedAlertTitle = styled.div`
    ${({theme: {palette}}) => css`
        & a {
            color: ${palette.primary[200]};
            cursor: pointer;
            text-decoration: underline;
        }
    `}
`
