import {
    AccessTokenSchema,
    AgentSchema,
    AttachSocialUserSchema,
    FeaturesGuestsSchema,
    GenderResponseSchema,
    GenderSchema,
    GuestPartialSchema,
    GuestSchema,
    HostPartialSchema,
    HostSchema,
    HostsAgentSchema,
    IdVerifiedSchema,
    LoginPayloadSchema,
    LoginSocialUserBodySchema,
    RepresentativeSchema,
    RepresentedUserSchema,
    UserSchema
} from '@/features/authentication/services/auth.schema.ts'
import {AppStatusesE} from '@/utilities/constants/appStatus'
import {AxiosRequestConfig} from 'axios'
import {z} from 'zod'
import {SignupGuestPayloadSchema, SignupGuestValidationSchema} from './services/signupGuest.schema'
import {
    SelectOptionCountriesValidationSchema,
    SelectOptionSocialMediaValidationSchema,
    SelectOptionWishToBecomeValidationSchema,
    SignupPayloadSchema,
    signupHostValidation
} from './services/signupHost.schema'
import {
    SignUpRepresentativePayloadSchema,
    SignupRepresentativeValidationSchema
} from './services/signupRepresentative.schema'
import {AuthEmailValidationSchema} from './services/authEmail.schena'
import {UserRoleE} from '@/types'

export type HostType = z.infer<typeof HostSchema>
export type GuestType = z.infer<typeof GuestSchema>
export type RepresentativeType = z.infer<typeof RepresentativeSchema>

export type HostPartialType = z.infer<typeof HostPartialSchema>
export type GuestPartialType = z.infer<typeof GuestPartialSchema>

export type User = z.infer<typeof UserSchema>

export type LoginPayload = z.infer<typeof LoginPayloadSchema>

export type FeaturesGuests = z.infer<typeof FeaturesGuestsSchema>

export type AuthState = {
    user: User
    access_token?: string
    // TODO refactor BE; remove after implemented
    access_token_v2?: string
    is_first_login?: boolean
    message?: string
}

export type GeneralError = Error & {
    response?: {data: {message: string}; status: number}
}
export enum AlertTypeE {
    GUEST_ENTER_NEW_PAYMENT_METHOD = 'PA_LEADER_001',
    GUEST_ENTER_NEW_PAYMENT_METHOD_AMG = 'PA_LEADER_002',
    GUEST_CREDIT_CARD_PROBLEMS = 'PA_LEADER_003',
    HOST_ACCOUNT_PENDING = 'PA_ROOKIE_001',
    HOST_ACCOUNT_APPROVED = 'PA_ROOKIE_002',
    HOST_ACCOUNT_ID_VERIFICATION = 'PA_ROOKIE_003'
}

export type LoginSocialUserBody = z.infer<typeof LoginSocialUserBodySchema>
export type AccessToken = z.infer<typeof AccessTokenSchema>
export enum AlertStatusE {
    DONATION_GOAL = 'donation-goal'
}
export type AlertStatus = AppStatusesE | AlertStatusE

export type SingleGenderType = z.infer<typeof GenderSchema>
export type GenderResponse = z.infer<typeof GenderResponseSchema>
export type SignupGuestValidation = z.infer<typeof SignupGuestValidationSchema>
export type SignupGuestPayload = z.infer<typeof SignupGuestPayloadSchema>
export type SignupHostValidation = z.infer<typeof signupHostValidation>
export type SignupHostPayload = z.infer<typeof SignupPayloadSchema>
export type SelectOptionWishToBecome = z.infer<typeof SelectOptionWishToBecomeValidationSchema>
export type SelectOptionCountries = z.infer<typeof SelectOptionCountriesValidationSchema>
export type IdVerifiedType = z.infer<typeof IdVerifiedSchema>

export type SignupRepresentativeValidation = z.infer<typeof SignupRepresentativeValidationSchema>
export type SignupRepresentativePayload = z.infer<typeof SignUpRepresentativePayloadSchema>

export type UploadVideoRequest = {
    body: FormData
    options: AxiosRequestConfig
}
export type LoginSocialUserRequest = {
    type: 'facebook' | 'google'
    authResponse: LoginSocialUserBody
}
export type AttachSocialUser = z.infer<typeof AttachSocialUserSchema>
export type SelectOptionSocialMedia = z.infer<typeof SelectOptionSocialMediaValidationSchema>

// For quick signup with email only
export type AuthEmailValidationType = z.infer<typeof AuthEmailValidationSchema>
export type AuthEmailPayload = {
    email: string
    user_role?: UserRoleE
}
export type AuthEmailVerifyResponseType = {
    email: string
    userRole: UserRoleE
    token: string
}

export type AgentType = z.infer<typeof AgentSchema>
export type HostAgentType = z.infer<typeof HostsAgentSchema>
export type RepresentedUserType = z.infer<typeof RepresentedUserSchema>
