import {useMe} from '@/features/authentication/queries/useMe.ts'
import {useRootStore} from '@/store'
import {selectChatStore} from '@/store/selectors'
import {UserRoleE} from '@/types.ts'
import {useQuery} from '@tanstack/react-query'
import {useEffect} from 'react'
import {CHAT_API} from '../services/chat.http'
import {QUERY_KEYS} from './keys'
import {getCorrectUserId} from '@/utilities/helpers'
import {LocalStorageManager} from '@/utilities/localStorage'

export const useGetChannels = (representedUserId?: number) => {
    const {data: user, loggedIn} = useMe()
    const {setChannels} = useRootStore(selectChatStore)
    const isRepresentative = user?.type === UserRoleE.Representative
    const isAgent = user?.type === UserRoleE.Agent
    const correctUserId = user ? getCorrectUserId(user) : undefined

    const correctUserType = isRepresentative || isAgent ? UserRoleE.Host : user?.type

    // TODO: this should be useInfiniteQuery
    const query = useQuery({
        queryKey: [QUERY_KEYS.fetchChannels, representedUserId],
        queryFn: () => CHAT_API.getChannels({represented_rookie_id: representedUserId}),
        enabled: !!user && !!loggedIn && !!LocalStorageManager.loginUser.get() && !!representedUserId
    })

    useEffect(() => {
        if (query.data && user && correctUserId && correctUserType) {
            setChannels(query.data, {id: correctUserId, role: correctUserType})
        }
    }, [query.data, user, correctUserId])
    // set the store data and return it
    return query
}

// const channels = useChatStore(store => store.channels)
