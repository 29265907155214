import {MicromorgiIcon} from '@/assets/icons/icons'
import {Modal} from '@/components/ui/modal/Modal'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useRootStore} from '@/store'
import {
    selectChoosePaymentsMethodModal,
    selectErrorModal,
    selectPaidGifsModal,
    selectSendMegaGiftModal,
    selectSuccessMegaGiftOverlay
} from '@/store/selectors'
import megaGift from '@assets/images/megaGift.png'
import {FC, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useSendMegaGift} from '../queries/useSendMegaGift'
import {
    StyledSendMegaGiftModalContainer,
    StyledSendMegaGiftModalDescription,
    StyledSendMegaGiftModalDescriptionCost,
    StyledSendMegaGiftModalImage,
    StyledSendMegaGiftModalSendButton,
    StyledSendMegaGiftModalWrapper
} from './style'
import {MEGA_GIFT} from '@/featuresConfig'
import useMixPanel from '@/integrations/mixpanel/hooks/useMixpanel'
import {MixpanelEventNameE} from '@/integrations/mixpanel/types'

export const SendMegaGiftModal: FC = () => {
    const {t} = useTranslation()
    const mixpanel = useMixPanel()
    const {
        closeModal: closeSendMegaGiftModal,
        isOpen: isOpenSendMegaGiftModal,
        hostID,
        onSuccessSendGift
    } = useRootStore(selectSendMegaGiftModal)
    const {closeModal: closePaidGifsModal, isOpen: isOpenPaidGifsModal} = useRootStore(selectPaidGifsModal)
    const {openModal: openSuccessMegaGiftOverlay} = useRootStore(selectSuccessMegaGiftOverlay)
    const {
        mutate: sendMegaGift,
        isPending: isLoadingSendMegaGift,
        error: errorSendMegaGift,
        data: dataSendMegaGift
    } = useSendMegaGift({hostID: String(hostID)})
    const {openErrorModal} = useRootStore(selectErrorModal)
    const {openModal: openChoosePaymentsMethodModal} = useRootStore(selectChoosePaymentsMethodModal)

    useEffect(() => {
        if (errorSendMegaGift && errorSendMegaGift.response) {
            if (errorSendMegaGift.response.status === 303) {
                openChoosePaymentsMethodModal({
                    paymentMethodUrls: errorSendMegaGift.response.data,
                    currencyAmount: 500,
                    morgisAmount: 5000,
                    description: 'choose_payment_method:description_micromorgi'
                })
            } else openErrorModal({message: errorSendMegaGift.message})
        }
        if (dataSendMegaGift) {
            isOpenSendMegaGiftModal && closeSendMegaGiftModal()
            isOpenPaidGifsModal && closePaidGifsModal()
            onSuccessSendGift?.()
            openSuccessMegaGiftOverlay({})
            mixpanel?.trackEvent(MixpanelEventNameE.MegaGiftSent)
        }
    }, [errorSendMegaGift, dataSendMegaGift])

    return (
        <Modal
            maxWidth="392px"
            minHeight="550px"
            title={t('sent_mega_gift_modal:title')}
            onClose={closeSendMegaGiftModal}
        >
            {isLoadingSendMegaGift && <Spinner />}
            <StyledSendMegaGiftModalContainer>
                <StyledSendMegaGiftModalWrapper>
                    <StyledSendMegaGiftModalImage src={megaGift} />
                    <StyledSendMegaGiftModalDescription>
                        {t(`sent_mega_gift_modal:subtitle`)}
                        <StyledSendMegaGiftModalDescriptionCost>
                            <MicromorgiIcon height={14} width={14} />
                            {MEGA_GIFT.cost}
                        </StyledSendMegaGiftModalDescriptionCost>
                    </StyledSendMegaGiftModalDescription>
                    <StyledSendMegaGiftModalSendButton
                        onClick={() => {
                            sendMegaGift({hostID: `${hostID}`})
                        }}
                    >
                        {t(`sent_mega_gift_modal:cta_text`)}
                    </StyledSendMegaGiftModalSendButton>
                </StyledSendMegaGiftModalWrapper>
            </StyledSendMegaGiftModalContainer>
        </Modal>
    )
}
