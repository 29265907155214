export const LOCAL_STORAGE_SHOW_SLOGAN_ANIMATION = 'showSloganAnimation'
export const LOCAL_STORAGE_LOGGED_IN = 'loggedIn'
export const LOCAL_STORAGE_SESSION_COUNT_FOR_ADD_HOME_SCREEN_BANNER = 'sessionCountForAddHomeScreenBanner'
export const LOCAL_STORAGE_IS_SAW_ON_BOARD_TOUR = 'isSawOnBoardTour'
export const LOCAL_STORAGE_GOAL_GUEST_TOUR_VIEWED = 'isGoalGuestTourViewed'
export const LOCAL_STORAGE_GOAL_HOST_TOUR_VIEWED = 'isGoalHostTourViewed'
export const LOCAL_STORAGE_CONFETTI_FOR_CASHBACK = 'count_of_confetti_for_cashback'
export const LOCAL_STORAGE_CONFETTI_FOR_ULTIMATE_STATUS = 'count_of_confetti_for_ultimate_status'
export const LOCAL_STORAGE_FIRST_ENTRY_CHAT = 'isFirstEntryToChat'
export const LOCAL_STORAGE_INTERACTIVE_FEED_SPECIAL_CARDS_TRACKER = 'interactiveFeedSpecialCardsTracker'
export const LOCAL_STORAGE_ROOKIE_ID_FOR_GOAL = 'rookieIdForGoal'
export const LOCAL_STORAGE_REFUSED_ULTIMATE_STATUS = 'isRefusedUltimateStatus'
export const LOCAL_STORAGE_50_ROOKIE_CARD_SESSION_COUNT = 'fiftyRookieCardSessionCount'
export const LOCAL_STORAGE_GIFT_PANEL_TAB = 'giftPanelTab'
export const LOCAL_STORAGE_SEEN_CONGRATS_MEGS_GIFT = 'seenCongratsMegaGift'
export const LOCAL_STORAGE_OPENED_CHANNEL_COUNT = 'openedChannelsCount'
export const LOCAL_STORAGE_LEADER_PAYMENT_START_LOCATION = 'paymentStartLocation'
export const LOCAL_STORAGE_UTM_PARAMS = 'utmParams'
export const LOCAL_STORAGE_SHOWN_ULTIMATE_MODAL_FIRST_TIME = 'shownUltimateModalFirstTime'
export const LOCAL_STORAGE_REPS_AGENT_INVITATION_TOKEN = 'repsAndAgentInvitationToken'
export const LOCAL_STORAGE_CHAT_USER_ID = 'chatUserId'
export const LOCAL_STORAGE_SEEN_TALENT_IDS = 'seenTalentIds'
