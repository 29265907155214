import {CreditCardTypeE} from '../../types'
import PaymentMethodCard from '../payment-method-card/PaymentMethodCard'
import {useTranslation} from 'react-i18next'
import paymentMethods from '@/assets/images/paymentMethods.png'

const ActivePaymentMethod = ({
    onClick,
    type,
    url,
    activeCreditCard
}: {
    onClick: (type: CreditCardTypeE, url: string) => void
    type?: CreditCardTypeE
    url?: string
    activeCreditCard: number | string | null
}) => {
    const {t} = useTranslation()
    if (!activeCreditCard || !url || !type) return null
    return (
        <PaymentMethodCard
            external={type === CreditCardTypeE.CCBILL}
            key={url}
            onClick={() => onClick(type, url)}
            icon={paymentMethods}
            title={t('choose_payment_method:pay_with_card')}
            subTitle={`${t('choose_payment_method:card_ending_in')} ****${activeCreditCard}`}
        />
    )
}

export default ActivePaymentMethod
