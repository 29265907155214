import {Modal} from '@/components/ui/modal/Modal'
import {useRootStore} from '@/store'
import {selectMeetMoreHostModal} from '@/store/selectors'
import {useMe} from '../authentication/queries/useMe'
import {useGetHost} from '../guest/queries/useGetHost'
import {useTranslation} from 'react-i18next'
import {
    StyledDivider,
    StyledMaskDiv,
    StyledModalContainer,
    StyledSubTitleWrapper,
    StyledSvgContainer,
    StyledTitleWrapper
} from './style'
import {Bubble} from '@/assets/icons/icons'
import {Button} from '@/components/ui/button/Button'
import MeetRookies from '@/assets/images/MeetRookies.png'
import {ROUTE_CHAT} from '@/utilities/constants/routeNames'
import {useNavigate} from 'react-router-dom'

export const MeetMoreHostModal = () => {
    const {data: user} = useMe()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const referredBy = user?.referred_by
    const {closeModal} = useRootStore(selectMeetMoreHostModal)
    const {data: host} = useGetHost({filter: referredBy ?? ''})

    const redirectTo = () => {
        navigate(ROUTE_CHAT) //TODO: add correct redirect and name for function
        closeModal()
    }

    return (
        <Modal title={t('meet_more_rookie:modal_title')} onClose={closeModal}>
            <StyledModalContainer>
                <StyledTitleWrapper>
                    <h1>
                        {t('meet_more_rookie:title', {USERNAME: ''})}
                        <br />
                        <span>
                            {host?.type_attributes?.first_name} {host?.type_attributes?.last_name}
                        </span>
                    </h1>
                </StyledTitleWrapper>
                <StyledSvgContainer>
                    <StyledMaskDiv
                        style={{
                            backgroundImage: `url(${host?.avatar?.url ?? MeetRookies})`
                        }}
                    />
                    <Bubble />
                </StyledSvgContainer>
                <StyledSubTitleWrapper>
                    <p>{t('meet_more_rookie:subtitle', {USERNAME: ''})}</p>
                    <StyledDivider />
                    <Button onClick={redirectTo}>
                        {t('meet_more_rookie:main_cta', {USERNAME: host?.type_attributes?.first_name})}
                    </Button>
                </StyledSubTitleWrapper>
            </StyledModalContainer>
        </Modal>
    )
}
