import {QueryCache, QueryClient} from '@tanstack/react-query'
import {GeneralError} from './features/authentication/types'
import {z} from 'zod'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            networkMode: 'online',
            retry: 1,
            retryOnMount: true,
            retryDelay: 1000,
            staleTime: 600000, //10 min
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: true
        }
    },
    queryCache: new QueryCache({
        // TODO: not final variant
        onError: (error: GeneralError) => {
            if (error instanceof z.ZodError) {
                console.error('ZodError', error.format())
            }

            if (error?.response?.status) {
                switch (true) {
                    case error?.response?.status >= 500:
                        throw error
                }
            }
            if (error instanceof z.ZodError) {
                console.error('ZodError', error.format())
            }
        },
    })
})

export const enum TErrCodes {
    ME_FETCH_FAILED
    // other error codes...
}
