import {useMutation} from '@tanstack/react-query'
import {MUTATION_KEYS} from './keys'
import {CHAT_API} from '../services/chat.http'
import { useGetRepresentedUser } from '@/hooks/useGetRepresentedHost'

export const useSendChatFile = (onSuccess?: () => void) => {
    const {representedUserId} = useGetRepresentedUser()
    
    return useMutation({
        mutationKey: [MUTATION_KEYS.send_file],
        mutationFn: ({ channelID, type, formDataObject }: { channelID: string; type: string; formDataObject: FormData }) => {
            if(!!representedUserId) {
                formDataObject.append('represented_rookie_id', representedUserId);
            }
            return CHAT_API.sendChatFile(channelID, type, formDataObject);
        },
        onSettled: () => {
            onSuccess?.()
        }
    })
}
