import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import usePusher from './hooks/usePusher'
import {useMe} from '@/features/authentication/queries/useMe'
import {AppStatusesE} from '@/utilities/constants/appStatus'
import {NotificationsType} from '@/features/notifications/types'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/features/notifications/queries/keys'
import {useRootStore} from '@/store'
import {selectAlertsStore, selectChatStore} from '@/store/selectors'
import {NOTIFICATION_TYPE_INIT_REQUIRED} from './notificationsInitRequired'
import {UserRoleE} from '@/types'
import {ROUTE_MESSAGE_CENTER} from '@/utilities/constants/routeNames'

export const PRIVATE_CHANNEL_NAME = 'private-user'

export enum USER_EVENTS_NAMES {
    userNotification = 'user_notification',
    channelTakeOver = 'channel_take_over'
}

export const useInitNotifications = () => {
    const {t} = useTranslation()
    const pusher = usePusher()
    const {data: user} = useMe()
    const isRepresentative = user?.type === UserRoleE.Representative
    const queryClient = useQueryClient()
    const {addAlert} = useRootStore(selectAlertsStore)
    const {setNeedReInitAfterNotification, needReInitAfterNotification} = useRootStore(selectChatStore)

    const initNotifications = useCallback(() => {
        if (pusher) {
            const channel = pusher.subscribe(`${PRIVATE_CHANNEL_NAME}.${user?.id}`)
            channel.bind('pusher:subscription_error', () => {
                console.log('FAILURE')
                addAlert({
                    message: t('common:notification_error'),
                    variant: AppStatusesE.FAILURE,
                    dismissTimeout: 5000
                })
            })
            channel.bind('pusher:subscription_succeeded', () => {
                console.log('SET SUBSCRIBED TRUE')
            })

            channel.bind(
                USER_EVENTS_NAMES.userNotification,
                (data: {type: USER_EVENTS_NAMES; data: NotificationsType[]}) => {
                    console.log('get notifications', data)
                    if (data.data) {
                        queryClient.invalidateQueries({queryKey: [QUERY_KEYS.notifications_history]})
                        queryClient.invalidateQueries({queryKey: [QUERY_KEYS.notification_unread_count]})
                        if (Object.values(NOTIFICATION_TYPE_INIT_REQUIRED).includes(data.data[0].type)) {
                            setNeedReInitAfterNotification(true)
                        }
                    }
                }
            )
            if (isRepresentative)
                channel.bind(
                    USER_EVENTS_NAMES.channelTakeOver,
                    (data: {type: USER_EVENTS_NAMES; data: NotificationsType[]}) => {
                        console.log('get notifications', data)
                        setNeedReInitAfterNotification(true)
                        location.href = `${origin}${ROUTE_MESSAGE_CENTER}`
                    }
                )
        }
    }, [pusher, user, needReInitAfterNotification])

    return initNotifications
}
