import {Modal} from '@/components/ui/modal/Modal'
import {
    StyledBody,
    StyledButton,
    StyledContent,
    StyledGoalCardWrapper,
    StyledSubtitle,
    StyledTextArea,
    StyledTextAreaWrapper,
    modalStyles
} from './style'
import {Trans, useTranslation} from 'react-i18next'
import {Button} from '@/components/ui/button/Button'
import {HostGoalCard} from '../host-goal-card/HostGoalCard'
import {useState} from 'react'
import {HaveBannedElementObj} from '@/features/generated-content-security/types'
import {selectGoalTeaserModal} from '@/store/selectors'
import {useRootStore} from '@/store'
import {useMe} from '@/features/authentication/queries/useMe'
import {HostType} from '@/features/authentication/types'
import {useParams} from 'react-router-dom'
import {useGetChannelDetails} from '@/features/chat/queries/useGetChannelDetails'
import useSendChatMessage from '@/features/chat/hooks/useSendChatMessage'
import {ChannelID, GroupParams} from '@/features/chat/types'
import {MessageTypeE} from '@/utilities/constants/chat'
import {getActiveGoalByUserRole} from '@/features/chat/helpers/helpers'
import {useGetRepresentedUser} from '@/hooks/useGetRepresentedHost'

export const GoalTeaserModal = ({
    onSendMessage = null,
    onCloseLocalModal = null,
    isBlockedSending = false,
    subtitleName
}: {
    onSendMessage?: ((params: GroupParams) => void) | null
    onCloseLocalModal?: (() => void) | null
    isBlockedSending?: boolean
    subtitleName?: string
}) => {
    const {t} = useTranslation()
    const {closeModal: onCloseSharedModal, guestID} = useRootStore(selectGoalTeaserModal)
    const {data: userData} = useMe()
    const host = userData as HostType
    const {representedUser} = useGetRepresentedUser()
    const goal = host && getActiveGoalByUserRole(representedUser ?? host) // Check if user has an active goal
    const {channel} = useParams()
    const {data: channelDetails} = useGetChannelDetails(channel as ChannelID)
    const {sendChatMessage, isPending} = useSendChatMessage(channel as ChannelID)

    const [isActive, setIsActive] = useState(false)
    const [messageText, setMessageText] = useState<string>('')
    const [haveBannedContent, setHaveBannedContent] = useState<HaveBannedElementObj | null>(null)

    const onChange = (value: string) => {
        setMessageText(value)
    }

    const closeModal = () => {
        onCloseLocalModal ? onCloseLocalModal() : onCloseSharedModal()
    }

    const sendGoalTeaser = async () => {
        if (!!onSendMessage && !!onCloseLocalModal) {
            const params = {
                message: messageText,
                teaser: MessageTypeE.GOAL_TEASER,
                goal_id: goal?.id
            }
            onSendMessage(params)
        } else {
            if (guestID) {
                await sendChatMessage({
                    text: messageText,
                    type: MessageTypeE.GOAL_TEASER,
                    userId: guestID,
                    goalId: goal?.id
                })
            }
        }
        closeModal()
    }

    return (
        <Modal onClose={closeModal} title={t('goal_teaser_modal:title')} modalStyles={modalStyles}>
            <StyledBody>
                <StyledSubtitle>
                    <Trans
                        t={t}
                        i18nKey="goal_teaser_modal:subtitle"
                        values={{LEADER_NAME: subtitleName ?? channelDetails?.username}}
                        components={{strong: <strong />}}
                    />
                </StyledSubtitle>
                <StyledContent isActive={isActive}>
                    <StyledGoalCardWrapper onClick={() => setIsActive(!isActive)}>
                        {!!goal && <HostGoalCard goal={goal} isGoalTeaser={true} isActive={isActive} />}
                    </StyledGoalCardWrapper>
                    {isActive && (
                        <StyledTextAreaWrapper>
                            <StyledTextArea
                                placeholder={t('goal_teaser_modal:placeholder')}
                                onChange={onChange}
                                getHaveBannedElements={setHaveBannedContent}
                                maxChars={120}
                                inModal
                            />
                            <StyledButton>
                                <Button
                                    disabled={!!haveBannedContent || (isBlockedSending && !messageText) || isPending}
                                    onClick={sendGoalTeaser}
                                >
                                    {t('goal_teaser_modal:button')}
                                </Button>
                            </StyledButton>
                        </StyledTextAreaWrapper>
                    )}
                </StyledContent>
            </StyledBody>
        </Modal>
    )
}
