import {
    StyledChatIconWrapper,
    StyledHeaderItem,
    StyledIcon,
    StyledNotificationBox,
    StyledNotificationsInfo,
    popoverStyles
} from './style.ts'
import {NotificationsIconEmpty, NotificationsIconFill} from '@/assets/icons/icons'
import {ROUTE_MESSAGE_CENTER, ROUTE_WELCOME_BACK_PAGE} from '@/utilities/constants/routeNames'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {RouteVisibilityFragment} from '@/components/ui/routeVisibilityFragment/RouteVisibilityFragment.tsx'
import useChat from '@/features/chat/hooks/useChat.ts'
import {Notifications} from '@/features/notifications/components/Notifications.tsx'
import {Popover} from '@/components/ui/popover/Popover.tsx'
import {useGetNotificationUnreadCount} from '@/features/notifications/queries/useGetNotificationUnreadCount.ts'
import {useTheme} from 'styled-components'
import useWindowDimensions from '@/hooks/useWindowDimensions.ts'
import {useLockBodyScroll} from '@/hooks/useLockBodyScroll.ts'
import {ChatHostsSelect} from '../chat-hosts-select/ChatHostsSelect.tsx'
import {ChatButton} from '../chat-button/ChatButton.tsx'
import {PrivateFragment} from '@/components/ui/privateFragment/PrivateFragment.tsx'
import {UserRoleE} from '@/types.ts'
import {useMe} from '@/features/authentication/queries/useMe.ts'

interface HeaderActionItemsProps {
    isLight?: boolean
}

export const HeaderActionItems = ({isLight = true}: HeaderActionItemsProps) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const {height: vh} = useWindowDimensions()
    const [showNotifications, setShowNotifications] = useState(false)
    const {data: lastReadTimeToken} = useGetNotificationUnreadCount()
    const newNotifications = lastReadTimeToken?.count && lastReadTimeToken?.count > 0
    useLockBodyScroll({enable: showNotifications})
    const {conversations} = useChat()
    const {data: user} = useMe()
    const isRepresentative = user?.type === UserRoleE.Representative
    const isAgent = user?.type === UserRoleE.Agent

    const unreadMessageCountSum = Object.values(conversations).reduce(
        (accumulator, item) => (accumulator += item?.unreadCounter ?? 0),
        0
    )

    const [representativeUnreadCountTotal, setRepresentativeUnreadCountTotal] = useState(0)

    const countRepsUnreadMessages = (num: number | null) => {
        setRepresentativeUnreadCountTotal((prevStateNum)=> num ? (prevStateNum + num) : 0)
    }
    const closeNotification = () => {
        setShowNotifications(false)
    }

    useEffect(()=>{
        if(typeof representativeUnreadCountTotal === 'number' && (representativeUnreadCountTotal < unreadMessageCountSum)) {
            setRepresentativeUnreadCountTotal(unreadMessageCountSum || 0)
        }
    },[unreadMessageCountSum])

    return (
        <RouteVisibilityFragment forbiddenRoutes={[ROUTE_WELCOME_BACK_PAGE]}>
            <StyledHeaderItem>
                <StyledChatIconWrapper unreadMessageCountSum={ unreadMessageCountSum || (isRepresentative ? representativeUnreadCountTotal : 0)}>
                    <span>{isRepresentative ? +(representativeUnreadCountTotal || 0) : unreadMessageCountSum}</span>
                    {!isRepresentative && !isAgent ? (
                        <div onClick={() => navigate(ROUTE_MESSAGE_CENTER)}>
                            <ChatButton isLight={isLight} />
                        </div>
                    ) : (
                        <ChatHostsSelect isLight={isLight} isAgent={isAgent} countRepsUnreadMessages={countRepsUnreadMessages}/>
                    )}
                </StyledChatIconWrapper>

                <PrivateFragment permittedRoles={[UserRoleE.Guest, UserRoleE.Host]}>
                    <Popover
                        popoverStyles={() => popoverStyles({theme, vh})}
                        contentProps={{align: 'end'}}
                        isModal
                        trigger={
                            !showNotifications ? (
                                <StyledIcon isLight={isLight}>
                                    <StyledNotificationsInfo newNotifications={!!newNotifications} />
                                    <NotificationsIconEmpty />
                                </StyledIcon>
                            ) : (
                                <StyledNotificationBox>
                                    <NotificationsIconFill />
                                </StyledNotificationBox>
                            )
                        }
                        rootProps={{
                            open: showNotifications,
                            onOpenChange: () => setShowNotifications(!showNotifications)
                        }}
                    >
                        <Notifications closeNotification={closeNotification} />
                    </Popover>
                </PrivateFragment>
            </StyledHeaderItem>
        </RouteVisibilityFragment>
    )
}
