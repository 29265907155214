import {CHAT_API} from '@/features/chat/services/chat.http'
import {useCallback, useEffect, useRef, useState} from 'react'
import Pubnub, {ListenerParameters} from 'pubnub'
import basePubnubConfig from '../pubnubConfig'
import {User} from '@/features/authentication/types.ts'
import useUnreadMessagesCount from '@/features/chat/hooks/useUnreadMessagesCount.ts'
import {ChannelID} from '@/features/chat/types.ts'
import {useRootStore} from '@/store'
import {selectChatStore} from '@/store/selectors.ts'
import {useHandleError} from '@/hooks/useHandleError'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/features/chat/queries/keys.ts'

const usePubnubInstance = (user?: Partial<User>, loggedIn?: boolean) => {
    const {
        setLastMessage,
        addMessage,
        channels,
        needReInitAfterNotification,
        setNeedReInitAfterNotification,
        needReInitAfterChangedRepresentedHost,
        setNeedReInitAfterChangedRepresentedHost
    } = useRootStore(selectChatStore)
    const [pubnubSDKInstance, setPubnubSDKInstance] = useState<Pubnub | undefined>(undefined)
    const [isChatLoading, setIsChatLoading] = useState(false)
    const [error, setError] = useState<Error | null>(null)
    const currentListeners = useRef(null)
    const queryClient = useQueryClient()

    const prevUser = useRef<Partial<User> | null>(null)
    useEffect(() => {
        // when user changes, everything has to be reinit
        if (user && user?.id !== prevUser.current?.id) {
            prevUser.current = user
            setupChat(user)
            if (needReInitAfterChangedRepresentedHost) {
                leaveChat()
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.fetchChannels, user?.id]})
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.fetchChannelDetails]})
                setNeedReInitAfterChangedRepresentedHost(false)
            }
        } else if (!loggedIn) {
            leaveChat()
        }
    }, [user, needReInitAfterChangedRepresentedHost])

    useEffect(() => {
        if (user && needReInitAfterNotification) {
            const reInitChat = async () => {
                await setupChat(user)
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.fetchChannels]})
                queryClient.invalidateQueries({queryKey: [QUERY_KEYS.fetchChannelDetails]})
                setNeedReInitAfterNotification(false)
            }
            reInitChat()
        }
    }, [needReInitAfterNotification])

    // Before unload, we remove all listeners and unsubscribe to all messages
    useEffect(() => {
        window.addEventListener('beforeunload', leaveChat)
        return () => {
            if (currentListeners.current && pubnubSDKInstance) {
                pubnubSDKInstance.removeListener(currentListeners.current)
                pubnubSDKInstance.unsubscribeAll()
            }
            leaveChat()
        }
    }, [])

    useHandleError({isError: !!error, error, isBlankPage: true})

    // Util to initialize the PubNub instance
    const initPubnub = async () => {
        try {
            if (!needReInitAfterNotification) {
                setIsChatLoading(true)
            }

            const params = {
                represented_rookie_id: user?.id
            }

            const {
                data: {uuid, authKey}
            } = await CHAT_API.initChat(params)
            return new Pubnub({
                ...basePubnubConfig,
                userId: `${uuid}`,
                authKey
            })
        } catch (err) {
            if (err instanceof Error) {
                setError(err)
            }
        } finally {
            setIsChatLoading(false)
        }
    }

    // Util to leave the chat
    const leaveChat = useCallback(() => {
        if (pubnubSDKInstance) {
            pubnubSDKInstance.unsubscribeAll()
        }
    }, [pubnubSDKInstance])

    // Global default listeners
    const listenerRef = useRef<ListenerParameters | null>(null)
    const addListeners = (pubnubInstance: Pubnub) => {
        if (listenerRef.current) {
            // clean and restart
            pubnubInstance.removeListener(listenerRef.current)
        }
        listenerRef.current = {
            message: receivedMessage => {
                const msg = {
                    channel: receivedMessage.channel as ChannelID,
                    message: receivedMessage.message,
                    timetoken: receivedMessage.timetoken,
                    messageType: receivedMessage.message.type,
                    uuid: receivedMessage.publisher
                }
                addMessage(receivedMessage.channel as ChannelID, msg)
                setLastMessage(receivedMessage.channel as ChannelID, msg)
            },
        }
        pubnubInstance.addListener(listenerRef.current)
    }

    // Channels subscriptions to receive messages
    const subscribeToChannels = (pubnubInstance: Pubnub, user: Partial<User>) => {
        if (user && pubnubInstance) {
            // Get user channel groups
            const channelGroups = user?.pubnub_groups?.map(cg => cg.name)
            // Get channels inside channel group
            channelGroups?.forEach(async cg => {
                const result = await pubnubInstance.channelGroups.listChannels({
                    channelGroup: cg
                })
                // Subscribe only if channel group contains at least one group
                if (result?.channels.length) {
                    pubnubInstance.subscribe({
                        channelGroups
                    })
                }
            })
        }
    }

    const setupChat = async (user: Partial<User>) => {
        const pubnubInstance = await initPubnub()

        if (pubnubInstance) {
            if (!Object.keys(channels).length) {
                leaveChat()
                addListeners(pubnubInstance)
            }
            subscribeToChannels(pubnubInstance, user)
            setPubnubSDKInstance(pubnubInstance)
        }
    }

    // Here we initialize the unread message counters
    useUnreadMessagesCount({pubnubInstance: pubnubSDKInstance})

    // We return the utils to initialize and leave the chat and the SDK instance
    return {pubnubSDKInstance, isChatLoading}
}

export default usePubnubInstance
