import {Button} from '@/components/ui/button/Button'
import styled, {DefaultTheme, css} from 'styled-components'

export const modalStyles = (theme: DefaultTheme) => css`
    max-height: 100vh;
    width: 100vw;
    ${theme.mediaQueries.m} {
        width: 500px;
        height: 100%;
    }
`

export const BuyMorgisModalContainer = styled.div`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        padding: 0 ${spacing * 2}px ${spacing * 2}px;
        overflow: auto;
        height: 100%;
        ${mediaQueries.m} {
            padding: 0 ${spacing * 3}px ${spacing * 2}px;
        }
    `}
`
export const BuyMorgisModalTitle = styled.h2`
    ${({theme: {spacing, typography}}) => css`
        ${typography.textXXl}
        margin: 0px 0px ${spacing * 2}px;
        text-align: center;
        font-weight: 400;
        letter-spacing: -0.05em;
    `}
`

export const BuyMorgisModalDescription = styled.p`
    ${({theme: {spacing, typography}}) => css`
        ${typography.textSm}
        color: rgb(115, 115, 115);
        margin: 0px 0px ${spacing * 3}px;
        text-align: center;
        font-weight: 400;
        line-height: 20px;
    `}
`
export const MicromorgiPackagesList = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-wrap: wrap;
        gap: ${spacing * 3}px;
        justify-content: space-between;
        padding: ${spacing}px 0px ${spacing * 1.5}px;
    `}
`
export const StyledExplanationBanner = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        ${typography.textSm}
        padding: ${spacing * 1.5}px;
        background-color: ${palette.success[25]};
        color: ${palette.success[600]};
        font-weight: 600;

        text-align: center;
        margin-bottom: ${spacing * 3}px;
        border-radius: ${spacing / 2}px;
    `}
`
export const StyledBuyMicromorgiButton = styled(Button)`
    ${({theme: {typography}}) => css`
        ${typography.textMd}
        font-weight: 600;
    `}
`
export const StyledChangePaymentMethodButton = styled(Button)`
    ${({theme: {typography}}) => css`
        ${typography.textSm}
        font-weight: 600;
    `}
`
export const StyledButtonsList = styled.div`
    ${() => css`
        display: flex;
        flex-direction: column;
        margin-top: auto;
    `}
`

export const StyledBannerWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin-bottom: ${spacing * 2}px;
    `}
`

export const StyledAmountListToggle = styled.span`
    text-align: center;
    cursor: pointer;
    text-decoration: underline;
    color: rgba(0, 6, 61, 0.5);
    padding-bottom: 16px;
`
