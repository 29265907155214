import styled, {css} from 'styled-components'

export const StyledHostSelectRoot = styled.div`
    ${({theme: {spacing, palette, zIndex}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 1.5}px;
        padding: ${spacing * 2}px ${spacing * 2}px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        position: absolute;
        right: 0;
        min-width: 150px;
        background-color: ${palette.neutral.white};
        z-index: ${zIndex.componentOverlay};
    `}
`

export const StyledConfirmIcon = styled.div<{checked: boolean}>`
    ${({theme: {spacing, palette}, checked}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${checked ? palette.primary['200'] : palette.neutral.white};
        border: 1px solid ${palette.primary['200']};
        height: 15px;
        width: 15px;
        border-radius: ${spacing / 2}px;
    `}
`

export const StyledOption = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        align-items: center;
        grid-template-columns: 0fr auto 0fr;
        grid-column-gap: ${spacing}px;
        cursor: pointer;
        font-weight: 700;
        text-wrap: nowrap;
    `}
`

export const StyledOptionText = styled.div`
    ${({theme: {palette}}) => css`
        font-weight: 400;
        color: ${palette.primary['800']};
    `}
`

export const StyledHostSelectContainer = styled.div`
    display: flex;
    align-items: center;
`

export const StyledEmptySection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
