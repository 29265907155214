import {InvitationAgentAndRepsResponseType} from '@/features/representative/types'
import axiosRefactor from '@/refactorClientApi'
import axios from '@/clientAPI'
import {InvitationAgentType} from '../types'
import {RepresentativeType, User} from '@/features/authentication/types'

export const AGENT_API = {
    sendAgentInvitation: async ({dataToSend}: {dataToSend: InvitationAgentType}): Promise<InvitationAgentType> => {
        const url = '/rookies/agents/join'
        const {data} = await axiosRefactor.post(url, dataToSend)
        return data
    },
    agentAcceptRequest: async (token: string) => {
        const url = `/rookies/agents/requests/accept/${token}`
        return await axiosRefactor.get(url)
    },
    verifyAgentInvitationToken: async (token: string): Promise<InvitationAgentAndRepsResponseType> => {
        const url = `/agents/invitations/verify/${token}`
        const {data} = await axiosRefactor.get(url)
        return data
    },
    getAgentHosts: async (): Promise<Partial<User>[]> => {
        const url = `/agents/rookies`
        const {data} = await axios.get(url, {params: {response_type: 'extended'}})
        return data
    },
    getAgentRepresentatives: async (): Promise<RepresentativeType[]> => {
        const url = `/agents/representatives`
        const {data} = await axios.get(url)
        return data
    },
    deleteAgent: async ({id}: {id: string}) => {
        const url = `/rookies/agents/${id}`
        return await axiosRefactor.delete(url)
    },
    getModes: async (): Promise<{id: number; keyName: string}[]> => {
        const url = `/modes`
        const {data} = await axiosRefactor.get(url)
        return data
    }
}
