import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {CHAT_API} from '../services/chat.http'
import {ChannelID, StoreChannelComplete} from '@/features/chat/types.ts'
import {useEffect} from 'react'
import {useRootStore} from '@/store'
import {selectChatStore} from '@/store/selectors'
import {useGetRepresentedUser} from '@/hooks/useGetRepresentedHost'

export const useGetChannelDetails = (channelID: ChannelID, enabled = true) => {
    const {setChannelDetails} = useRootStore(selectChatStore)
    const {representedUserId} = useGetRepresentedUser()
    const channel = useRootStore(store => store.chat.channels[channelID])

    const query = useQuery({
        queryKey: [QUERY_KEYS.fetchChannelDetails, channelID, representedUserId],
        queryFn: () => CHAT_API.getChannelDetails(channelID, representedUserId),
        enabled: !!channelID && enabled
    })

    useEffect(() => {
        if (query.data) {
            setChannelDetails(channelID, query.data)
        }
    }, [query.data])

    return {
        ...query,
        data: query.isSuccess ? (channel as StoreChannelComplete) : null
    }
}
