import React from 'react'
import {
    StyledCardContent,
    StyledCardContentImages,
    StyledCardContentTitle,
    StyledCardWrapper,
    StyledMainTitle,
    StyledSubTitle
} from './style'
import {SquareWithArrowIcon} from '@/assets/icons/icons'

type PaymentMethodCardProps = {
    onClick: () => void
    icon?: string
    title?: string
    subTitle?: string
    external?: boolean
}

const PaymentMethodCard: React.FC<PaymentMethodCardProps> = ({onClick, icon, title, subTitle, external = false}) => {
    return (
        <StyledCardWrapper onClick={onClick}>
            <StyledCardContent>
                {icon && (
                    <StyledCardContentImages>
                        <img src={icon} alt="payment" />
                    </StyledCardContentImages>
                )}
                <StyledCardContentTitle>
                    <StyledMainTitle>{title}</StyledMainTitle>
                    <StyledSubTitle>{subTitle}</StyledSubTitle>
                </StyledCardContentTitle>
            </StyledCardContent>
            {external && <SquareWithArrowIcon />}
        </StyledCardWrapper>
    )
}

export default PaymentMethodCard
