export enum CreditCardTypeE {
    CCBILL = 'ccbill',
    PRAXIS = 'praxis'
}
export enum SocialPlatformTypeE {
    GOOGLE = 'google',
    FACEBOOK = 'facebook'
}

export enum CreditCardsNameE {
    PRAXIS_CREDIT_CARD = 'praxis_credit_card',
    CCBIL_CREDIT_CARD = 'ccbill_credit_card',
    PRAXIS_GENERIC = 'praxis_generic'
}

export type PaymentMethodUrls = {
    [CreditCardsNameE.CCBIL_CREDIT_CARD]?: string
    [CreditCardsNameE.PRAXIS_CREDIT_CARD]?: string
    [CreditCardsNameE.PRAXIS_GENERIC]?: string
}

export type PaymentError = Error & {
    response?: {data: PaymentMethodUrls; status: number}
}

export type MonthlySupportError = Error & {
    response?: {data: {url?: PaymentMethodUrls}; status: number}
}
