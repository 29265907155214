import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {AGENT_API} from '../services/agent.http'

export const useGetAgentHosts = (enabled = true) => {
    return useQuery({
        queryKey: [QUERY_KEYS.agent_rookies],
        queryFn: () => AGENT_API.getAgentHosts(),
        enabled
    })
}
