import Pubnub from 'pubnub'
import basePubnubConfig from '../../chat/pubnubConfig'
import {CHAT_API} from '@/features/chat/services/chat.http'
import {useCallback, useEffect, useState} from 'react'
import {useHandleError} from '@/hooks/useHandleError'
import {ChannelsType} from '../types'
import {HostPartialType} from '@/features/authentication/types'

const useUnreadMessagesCountReps = ({host, countRepsUnreadMessages}: {host: HostPartialType & {hostChannels?: ChannelsType},  countRepsUnreadMessages: (num: number | null)=> void}) => {
    const [error, setError] = useState<Error | null>(null)
    const [isMessageCountInitialized, setIsMessageCountInitialized] = useState(false)
    const [count, setCount] = useState(0)

    const initPubnub = async ({hostId}: {hostId: number}) => {
        try {
            const params = {
                represented_rookie_id: hostId
            }

            const {
                data: {uuid, authKey}
            } = await CHAT_API.initChat(params)
            return new Pubnub({
                ...basePubnubConfig,
                userId: `${uuid}`,
                authKey
            })
        } catch (err) {
            if (err instanceof Error) {
                setError(err)
            }
        }
    }

    const getMessageCounts = async ({pubnubInstance, channels}: {pubnubInstance: Pubnub; channels: ChannelsType}) => {
        try {
            if (channels && Object.keys(channels).length && pubnubInstance && !isMessageCountInitialized) {
                const channelsNames: string[] = []
                const channelsLastReadTimetokens: string[] = []
                Object.values(channels).forEach(value => {
                    const channelName = value.display_name
                    const channelItem = value
                    if (channelItem.last_reads_timetokens?.length) {
                        channelsNames.push(channelName)
                        const lastReadTimetoken = channelItem.last_reads_timetokens[0].timetoken
                        channelsLastReadTimetokens.push(lastReadTimetoken)
                    }
                })
                const counters = await pubnubInstance.messageCounts({
                    channels: channelsNames,
                    channelTimetokens: channelsLastReadTimetokens
                })
                let count: number = 0
                Object.values(counters.channels).forEach(value => {
                    count = count + value
                })
                setIsMessageCountInitialized(true)
                return count
            }
        } catch (error) {
            console.log('Error in getting message counts', error)
        }
    }

    let pubnubInstance: Pubnub | null | undefined = null
    const getCount = async () => {
        countRepsUnreadMessages(null)
        pubnubInstance = host && host.id ? await initPubnub({hostId: host.id}) : null
        const count =
            pubnubInstance &&
            host &&
            host.hostChannels &&
            (await getMessageCounts({pubnubInstance, channels: host.hostChannels}))
        setCount(count || 0)
        countRepsUnreadMessages(count || null)
    }

    const leaveChat = useCallback(() => {
        if (pubnubInstance) {
            console.log('leaveChat')
            pubnubInstance.unsubscribeAll()
        }
    }, [pubnubInstance])

    useHandleError({isError: !!error, error, isBlankPage: true})

    useEffect(() => {
        getCount()
        return () => {
            leaveChat()
        }
    }, [host, pubnubInstance])

    return count
}

export default useUnreadMessagesCountReps
