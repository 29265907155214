import {UserStatusesE} from '@/features/constants/constants'

export const USER_ROLE_GUEST = 'leader'
export const USER_ROLE_HOST = 'rookie'
export const USER_ROLE_REPRESENTATIVE = 'representative'
export const USER_ROLE_AGENT = 'agent'

export const BLOCKED_USER_STATUSES = [UserStatusesE.REJECTED, UserStatusesE.BLOCKED]
export const BLOCKED_COUNTRIES = ['AND', 'ISR']

export enum IdCardStatusesE {
    Pending = 'pending',
    Approved = 'approved',
    Rejected = 'rejected',
    Default = 'default'
}
