import {useMutation} from '@tanstack/react-query'
import {MUTATION_KEYS} from './keys'
import {GUEST_API} from '../services/guest.http'
import {AxiosResponse} from 'axios'
import {SubscriptionsUpdate} from '../types'
import {PaymentError} from '@/features/guest-payments/types'

export const useRenewSubscription = () => {
    return useMutation<AxiosResponse, PaymentError, SubscriptionsUpdate>({
        mutationKey: [MUTATION_KEYS.renew_subscription],
        mutationFn: GUEST_API.renewSubscription
    })
}
