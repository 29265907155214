import {FeedCard} from '@/features/feed/types.ts'
import {GuestType, HostPartialType} from '@/features/authentication/types.ts'
import {create} from 'zustand'
import {computeIndexes} from '@/features/feed/utils.ts'
import {reduce} from 'lodash'
import {SessionStorageManager} from '@/utilities/sessionStorage'

type FeedState = {
    count: number
    emptyDeckCounter: number
    currentIndex: number
    localIndex: number
    isCardOpen: boolean
    isMarketingCard: boolean
    swipedCardCounter: number
    feedStats: {
        currentSession: {[key: string]: number} // {9949: 0, 8987: 34}:
        alreadySeenRookieIDs: string[] // [9949, ]
    }
    hostCards: HostPartialType[]
    availableCards: FeedCard[]
    isFetchingWithHostFilters: boolean
    refreshCount: number
    refreshCountInitialized: boolean
}

type FeedActions = {
    increaseSwipedCardCounter: (params: Omit<FeedState, 'isOpen'>) => void
    shuffleDeck: (
        hostCards: HostPartialType[],
        reset: boolean,
        isFiltersExist: boolean,
        guestProfile: GuestType,
        metaCombiCard: boolean
    ) => void
    updateCardView: (seenCardID: string) => void
    seenHosts: () => string[]
    setIsFetchingWithHostFilters: (isFiltersChanged: boolean) => void
}

type FeedStore = FeedState & FeedActions

const initialFeed: FeedState = {
    count: 0,
    emptyDeckCounter: 0,
    currentIndex: -1,
    localIndex: 0,
    isCardOpen: false,
    isMarketingCard: false,
    swipedCardCounter: 0,
    refreshCount: 0,
    refreshCountInitialized: false,
    feedStats: {
        currentSession: {},
        alreadySeenRookieIDs: []
    },
    hostCards: [],
    availableCards: [],
    isFetchingWithHostFilters: false
}

// Todo: install [Immerjs](https://immerjs.github.io/immer/)
export const useFeedStore = create<FeedStore>(set => ({
    ...initialFeed,
    refreshCount: SessionStorageManager.reloadPageCount.get(),
    refreshCountInitialized: false,
    increaseSwipedCardCounter: () =>
        set(state => ({
            swipedCardCounter: state.swipedCardCounter + 1
        })),
    updateCardView: (seenCardID: string) =>
        set(state => ({
            feedStats: {
                ...state.feedStats,
                currentSession: {
                    ...state.feedStats.currentSession,
                    [seenCardID]: 1
                }
            }
        })),
    seenHosts: () => {
        let results: string[] = []
        set(state => {
            const {alreadySeenRookieIDs, currentSession} = state.feedStats
            const toBeSeenRookieIDs = reduce(
                currentSession,
                (result: string[], value, key) => {
                    // if cardType includes in special cards, not push
                    const cardWasScrolledInCarousel = value > 0
                    const isNotSpecialCard = !Number.isNaN(+key) // special card id starts with "marketing-0001"
                    const notAlreadySeen = isNotSpecialCard && !alreadySeenRookieIDs.includes(`${key}`)
                    if (cardWasScrolledInCarousel && notAlreadySeen) {
                        result.push(`${key}`)
                    }
                    return result
                },
                []
            )
            results = [...toBeSeenRookieIDs]

            return {
                feedStats: {
                    ...state.feedStats,
                    alreadySeenRookieIDs: [...state.feedStats.alreadySeenRookieIDs, ...toBeSeenRookieIDs]
                }
            }
        })
        return results
    },
    setIsFetchingWithHostFilters: (isFiltersChanged: boolean) =>
        set(() => {
            return {isFetchingWithHostFilters: isFiltersChanged}
        }),

    shuffleDeck: (
        hostCards: HostPartialType[],
        reset: boolean, // this is used to reset when filters change
        isFiltersExist: boolean,
        guestProfile: GuestType,
        metaCombiCard: boolean
    ) =>
        set(state => {
            // if not empty but equal, stop
            // const prevHostIds = state.hostCards.map(h => h.id)
            // const currHostIds = hostCards.map(h => h.id)
            // if (!!prevHostIds.length && isEqual(prevHostIds, currHostIds)) {
            //     return {state}
            // }

            const newDeckWithSpecialCards = computeIndexes({
                initialDeck: [...hostCards],
                prevSession: {...state.feedStats.currentSession},
                continuePreviousSession: true,
                guestProfile,
                isFiltersExist,
                metaCombiCard,
                refreshCount: state.refreshCount
            }) as FeedCard[]

            const currentSession: {[key: string]: number} = {}
            newDeckWithSpecialCards.forEach((card: FeedCard) => {
                currentSession[card.id] = 0
            })
            const availableCards = reset
                ? newDeckWithSpecialCards
                : state.availableCards.concat(newDeckWithSpecialCards)

            let newRefreshCount = state.refreshCount
            if (!state.refreshCountInitialized) {
                newRefreshCount += 1
                SessionStorageManager.reloadPageCount.set(newRefreshCount)
            }
            return {
                currentIndex: reset ? 0 : state.currentIndex,
                refreshCount: newRefreshCount, // Increment refreshCount
                refreshCountInitialized: true,
                feedStats: {
                    ...state.feedStats,
                    currentSession
                },
                hostCards,
                availableCards,
                isFetchingWithHostFilters: false
            }
        })
}))

export default useFeedStore
