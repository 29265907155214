import {Alert} from '@/components/commons/alert/Alert'
import {useMe} from '@/features/authentication/queries/useMe'
import {StyledBlockedAlertTitle, StyledWrapper} from './style'
import {ProfileAlert} from '@/components/commons/alert/ProfileAlert'
import {ConnectorNamesE} from '@/features/moengage/services/moengage.model'
import {BLOCKED_COUNTRIES} from '@/utilities/constants/user'
import {Trans, useTranslation} from 'react-i18next'
import {AppStatusesE} from '@/utilities/constants/appStatus'
import {envVars} from '@/envVars'
import {useGetLocalize} from '@/features/authentication/queries/useGetLocalize'
import {useMoengageCombinationUI} from '@/integrations/moengage/MoengageProvider'
import {useRootStore} from '@/store'
import {selectAlertsStore, selectModals} from '@/store/selectors'
import useContainerDimensions from '@/hooks/useContainerDimensions'

export const AlertsSection = () => {
    const {t} = useTranslation()
    const {data: user} = useMe()
    const {data: localize} = useGetLocalize()
    const {heightOfHeader} = useContainerDimensions()
    const userInBlockedCountry = BLOCKED_COUNTRIES.includes(`${localize?.alpha_3_code}`)
    const {alerts, removeAlert} = useRootStore(selectAlertsStore)
    const {moengageCombinationUI, combinationName} = useMoengageCombinationUI()
    const hasProfileAlerts = !!user && !!user.profile_alerts.length
    const hasAlerts = !!alerts.length
    const hasMoengageTopHeaderBanner =
        !!combinationName && combinationName === ConnectorNamesE.banner_top_header && !!moengageCombinationUI

    const globalModals = useRootStore(selectModals)

    const isOpenModals = Boolean(
        document.querySelector('[id*="modal_with_"]') || Object.values(globalModals).find(({isOpen}) => isOpen)
    )

    if (!hasProfileAlerts && !hasAlerts && !hasMoengageTopHeaderBanner && !userInBlockedCountry) return null

    return (
        <StyledWrapper $heightOfHeader={heightOfHeader} isOpenModals={isOpenModals}>
            {hasMoengageTopHeaderBanner && moengageCombinationUI}
            {user?.profile_alerts.map(
                (alert, index) => alert && <ProfileAlert key={`${alert.code}-${index}`} alertCode={alert.code} />
            )}
            {alerts.map((alert, index) => (
                <Alert
                    key={index}
                    variant={alert.variant}
                    message={alert.message}
                    title={alert.title}
                    dismissTimeout={alert.dismissTimeout}
                    onDismiss={() => removeAlert(index)}
                    onAlertClick={alert.onAlertClick}
                />
            ))}
            {userInBlockedCountry && (
                <Alert
                    variant={AppStatusesE.DEFAULT}
                    title={
                        <StyledBlockedAlertTitle>
                            <Trans
                                t={t}
                                i18nKey="blocked_countries"
                                components={{
                                    a: <a href={envVars.VITE_APP_FACEBOOK_GROUP} target={'_blank'} />
                                }}
                            />
                        </StyledBlockedAlertTitle>
                    }
                />
            )}
        </StyledWrapper>
    )
}
