import {ChannelID} from '@/features/chat/types'
import {useSetLastReadTimetoken} from '../queries/useSetLastReadTimetoken'
import {usePubNub} from 'pubnub-react'
import {useRootStore} from '@/store'
import {selectChatStore} from '@/store/selectors.ts'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/features/chat/queries/keys.ts'
import {CHAT_API} from '@/features/chat/services/chat.http.ts'
import {useGetRepresentedUser} from '@/hooks/useGetRepresentedHost'

const useMarkAsRead = (channelID: ChannelID) => {
    const queryClient = useQueryClient()
    const {mutate: setLastReadTimetoken} = useSetLastReadTimetoken(channelID)
    const {setUnreadCounter} = useRootStore(selectChatStore)
    const pubnub = usePubNub()
    const {representedUserId} = useGetRepresentedUser()
    // Util to mark as read in pubnub
    const pubnubMarkAsRead = (lastReadTimetoken: string) => {
        if (channelID) {
            pubnub.objects.setMemberships({
                channels: [
                    {
                        id: channelID,
                        custom: {
                            lastReadTimetoken
                        }
                    }
                ]
            })
        }
    }

    // Here we mark as read in pubnub and backend
    const markAsRead = async (messageTimetoken: string) => {
        try {
            const channelDetails = await queryClient.fetchQuery({
                queryKey: [QUERY_KEYS.fetchChannelDetails, channelID, representedUserId],
                queryFn: () => CHAT_API.getChannelDetails(channelID, representedUserId)
            })
            pubnubMarkAsRead(messageTimetoken)
            if (channelID && channelDetails?.channel_id) {
                setLastReadTimetoken({
                    timetoken: messageTimetoken,
                    channelID: channelDetails.channel_id.toString()
                })
                setUnreadCounter(channelID, 0) // TODO: here race-condition can happen
            }
        } catch (err) {
            console.log('Mark as read error', err)
        }
    }

    return {markAsRead}
}

export default useMarkAsRead
