export const MUTATION_KEYS = {
    agent_invitation: 'agent-invitation',
    delete_agent: 'delete-agent'
}

export const QUERY_KEYS = {
    agent_accept_request: 'agent-accept-request',
    verify_agent_invitation: 'verify-agent-invitation',
    agent_rookies: 'agent-rookies',
    agent_representatives: 'agent-representatives',
    modes: 'modes'
}
