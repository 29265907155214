import {Spinner} from '@/components/ui/spinner/Spinner'
import {useLogout} from '@/features/authentication/queries/useLogout'
import {useMe} from '@/features/authentication/queries/useMe.ts'
import {UserStatusesE} from '@/features/constants/constants'
import {useGetRepresentedUser} from '@/hooks/useGetRepresentedHost'
import {UserRoleE} from '@/types'
import * as routes from '@/utilities/constants/routeNames'
import {BLOCKED_USER_STATUSES} from '@/utilities/constants/user'
import {useEffect} from 'react'
import {Navigate, Outlet} from 'react-router-dom'

export const BlockedUserGuard = () => {
    const {data: user} = useMe()
    const {mutate} = useLogout()
    const isUserBlocked = !!user && BLOCKED_USER_STATUSES.includes(user.status)
    const isUserFraud = !!user && user?.status === UserStatusesE.FRAUD
    const isAgent = !!user && user?.type === UserRoleE.Agent
    const isRepresentative = !!user && user?.type === UserRoleE.Representative
    const {agentAndRepsHosts, isLoading} = useGetRepresentedUser()

    useEffect(() => {
        if (isUserFraud) {
            mutate()
        }
    }, [user])

    if (isLoading) return <Spinner />

    switch (true) {
        case isUserBlocked:
            return <Navigate to={routes.ROUTE_BLOCKED_USER} />
        case agentAndRepsHosts && !agentAndRepsHosts?.length && (isAgent || isRepresentative):
            return <Navigate to={routes.ROUTE_NO_HOSTS} />
        default:
            return <Outlet />
    }
}
