import {Chat, ChatFill} from '@/assets/icons/icons'
import {ROUTE_MESSAGE_CENTER} from '@/utilities/constants/routeNames'
import {useLocation} from 'react-router-dom'
import {StyledFilledIcon, StyledIcon} from './style'

interface ChatButtonProps {
    isLight?: boolean
}

export const ChatButton = ({isLight = true}: ChatButtonProps) => {
    const location = useLocation()

    return (
        <>
            {!location?.pathname.includes(ROUTE_MESSAGE_CENTER) ? (
                <StyledIcon isLight={isLight}>
                    <Chat />
                </StyledIcon>
            ) : (
                <StyledFilledIcon>
                    <ChatFill />
                </StyledFilledIcon>
            )}
        </>
    )
}
