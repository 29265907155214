import {createGlobalStyle, withTheme} from 'styled-components'
import {palette} from '@/theme/palette.ts'

const ResetStyles = createGlobalStyle`
  * {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  }


  html{
    height: 100%;
    font-size: 14px;
    line-height: 1.4;
    font-family: 'Inter';
    color: ${palette.light.primary[900]};
    background-color: ${palette.light.neutral.white};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: none;
  }

  body {
    // Instead of "height: 100%" here we put the "min-height: 100%" rule to make the "cash-back" sticky banner work
    // check this for reference https://stackoverflow.com/questions/46655386/when-css-position-sticky-stops-sticking
    min-height: 100%;
  }

  #root {
    height: 100%;
  }

  h1, h2, h3, h4, h5, h6, p, span, small {
    margin-bottom: 0;
  }
  label, input, textarea, select, button {
    font-family: inherit;
  }

  textarea {
    overflow-wrap: break-word;
  }

  small {
    font-size: smaller;
  }

  //Reset Css

  /* Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property */
  *:where(:not(iframe, canvas, img, svg, video, div, strong):not(svg *)) {
    all: unset;
    display: revert;
  }

  /* Preferred box-sizing value */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  /* Remove list styles (bullets/numbers) */
  ol, ul {
    list-style: none;
  }

  /* For images to not be able to exceed their container */
  img {
    max-width: 100%;
  }

  /* Removes spacing between cells in tables */
  table {
    border-collapse: collapse;
  }

  /* Revert the 'white-space' property for textarea elements on Safari */
  textarea {
    white-space: revert;
  }

  /* Change the white to any color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  #sprite-plyr {
    display: none;
  }

  svg{
    flex-shrink: 0;
  }
`

export default withTheme(ResetStyles)
