import styled, {DefaultTheme, css} from 'styled-components'

export const StyledIcon = styled.div`
    ${({isLight, theme: {palette, mediaQueries}}: {isLight: boolean; theme: DefaultTheme}) => css`
        display:flex;
        & svg {
            ${mediaQueries.m} {
                width: 24px;
                height: 24px;
            }
            & path {
                fill:  ${isLight ? palette.primary[900] : palette.neutral.white};
            },
        }
    `}
`

export const StyledFilledIcon = styled.div`
    ${({theme: {mediaQueries}}) => css`
        & svg {
            ${mediaQueries.m} {
                width: 24px;
                height: 24px;
            }
        }
    `}
`
