import styled, {DefaultTheme, css} from 'styled-components'

export const popoverStyles = ({theme, vh}: {theme: DefaultTheme; vh: number}) => css`
    height: ${vh}px;
    width: 100vw;
    box-shadow: none;
    padding-bottom: ${theme.spacing}px;
    border-radius: 0;
    ${theme.mediaQueries.m} {
        width: 500px;
        height: 100%;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        box-shadow: ${theme.shadows['9xl']};
        max-height: 80vh;
        min-height: 40vh;
    }
`
export const StyledHeaderItem = styled.div`
    ${({theme: {spacing}}) => css`
        position: relative;
        display: grid;
        grid-auto-flow: column;
        justify-self: end;
        grid-column-gap: ${spacing * 2}px;
        & svg {
            width: 20;
            height: 20
        },
        `}
`

export const StyledChatIconWrapper = styled.div`
    ${({unreadMessageCountSum, theme: {palette}}: {unreadMessageCountSum: number; theme: DefaultTheme}) => css`
        position: relative;
        text-decoration: none;
        height: 20px;
        width: 20px;
        cursor: pointer;
        & svg {
            height: 20px;
            width: 20px;
        }
        & span {
            font-size: 8px;
            display: ${unreadMessageCountSum ? 'flex' : 'none'};
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -7px;
            right: -7px;
            background-color: ${palette.danger[25]};
            font-weight: 600;
            border-radius: 8px;
            padding: 3px;
            color: ${palette.neutral.white};
            min-height: 16px;
            min-width: 16px;
        }
    `}
`

export const StyledNotificationIconWrapper = styled.div`
    ${({
        unreadNotificationCounts,
        showNotifications,
        theme: {palette}
    }: {
        unreadNotificationCounts: number
        showNotifications: boolean
        theme: DefaultTheme
    }) => css`
    position: relative;
    cursor: pointer;
    height: 20px;
    width: 20px;
    & svg {
        height: 20px;
        width: 20px;
    }
    &::before {
        display: 
            ${unreadNotificationCounts && !showNotifications ? 'block' : 'none'};
        content: '';
        position: absolute;
        top: -2px;
        right: -2px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: ${palette.danger[25]};
    },
    `}
`

export const StyledIcon = styled.div`
    ${({isLight, theme: {palette, mediaQueries}}: {isLight: boolean; theme: DefaultTheme}) => css`
        display:flex;
        & svg {
            ${mediaQueries.m} {
                width: 24px;
                height: 24px;
            }
            & path {
                fill:  ${isLight ? palette.primary[900] : palette.neutral.white};
            },
        }
    `}
`

export const StyledNotificationBox = styled.div`
    ${({theme: {mediaQueries}}) => css`
            display: flex;
            ${mediaQueries.m} {
                & svg {
                    width: 24px;
                    height: 24px;
                }
            }
        `}
    `

export const StyledNotificationsInfo = styled.div`
    ${({newNotifications, theme: {palette}}: {newNotifications: boolean; theme: DefaultTheme}) => css`
        display: ${newNotifications ? 'block' : 'none'};
        position: absolute;
        width: 10px;
        height: 10px;
        left: 48px;
        top: -4px;
        border-radius: 50%;
        background-color: ${palette.danger[25]};
    `}
`
