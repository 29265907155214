export const QUERY_KEYS = {
    //TODO rename for same spelling like: send_message: 'send-message'
    fetchChannels: 'fetchChannels',
    agent_channels: 'agent-channels',
    reps_channels: 'reps-channels',
    fetchChannelDetails: 'fetchChannelDetails',
    fetchAnimationDetails: 'fetchAnimationDetails',
    fetchAnimationsList: 'fetchAnimationsList',
    fetchChatFile: 'fetchChatFile',
    chatChannels: 'chatChannels',
    fetchGroupList: 'fetchGroupList',
    getUnlockedMessage: 'getUnlockedMessage',
    getUnlockedMediaMessage: 'getUnlockedMediaMessage',
    report_categories: 'report-categories',
    get_locked_paid_message: 'get-locked-paid-message'
}

export const MUTATION_KEYS = {
    send_file: 'send-file',
    pause_connection: 'pause-connection',
    send_message: 'send-message',
    setLastReadTimetoken: 'setLastReadTimetoken',
    send_chat_report: 'send-chat-report',
    sendGroupMessage: 'sendGroupMessage',
    createBroadcast: 'createBroadcast',
    unlockTextMessage: 'unlockTextMessage',
    createChatWithGuest: 'createChatWithGuest',
    takeOver: 'take-over'
}
