import {MorgiIcon} from '@/assets/icons/icons'
import {Modal} from '@/components/ui/modal/Modal'
import {getLocalDate} from '@/dayjs'
import {useMe} from '@/features/authentication/queries/useMe'
import {AmountItem} from '@/features/gift/components/amount-item/AmountItem'
import {useGetHost} from '@/features/guest/queries/useGetHost'
import {useRootStore} from '@/store'
import {selectCancelRecurringGiftModal, selectChoosePaymentsMethodModal, selectErrorModal} from '@/store/selectors'
import {REDIRECT_MAP} from '@/utilities/constants'
import {FC, useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {useDeleteGift} from '../queries/useDeleteGift'
import {useFetchSubscribedAmounts} from '../queries/useFetchSubscribedAmounts'
import {useFetchSubscribedHosts} from '../queries/useFetchSubscribedHosts'
import {useSendGift} from '../queries/useSendGift'
import {DonationType} from '../types'
import {
    StyledAnimationBox,
    StyledButtonContainer,
    StyledCancelButton,
    StyledCancelRecurringGiftModalContainer,
    StyledCancelRecurringGiftModalDescription,
    StyledCancelRecurringGiftModalStepSecondContainer,
    StyledCancelRecurringGiftModalStepSecondDescription,
    StyledCancelRecurringGiftModalStepSecondTitle,
    StyledCancelRecurringGiftModalStepThird,
    StyledCancelRecurringGiftModalStepThirdContainer,
    StyledCancelRecurringGiftModalStepThirdDescription,
    StyledCancelRecurringGiftModalStepThirdTitle,
    StyledConfirmButton,
    StyledHostsAvatar,
    StyledLimitedAmountsList,
    StyledMeetButton,
    StyledRejectButton
} from './style'
import Cry from '@/assets/animations/Cry.json'
import {Animation} from '@/components/ui/animation/Animation'
import {SubscriptionStatusesE} from '@/features/chat/helpers/helpers'

type steps = 1 | 2 | 3
type StepProps = {onChangePage: (step: steps) => void; closeModal: () => void}
const STEPS_MAP = {
    1: (props: StepProps) => <CancelRecurringGiftModalStepOne {...props} />,
    2: (props: StepProps) => <CancelRecurringGiftModalStepSecond {...props} />,
    3: (props: StepProps) => <CancelRecurringGiftModalStepThird {...props} />
}

export const CancelRecurringGiftModal: FC = () => {
    const {t} = useTranslation()
    const {closeModal} = useRootStore(selectCancelRecurringGiftModal)
    const [step, setStep] = useState<steps>(1)
    return (
        <Modal title={t('cancel_rookie_gift:modal_title')} onClose={closeModal} width="350px">
            <StyledCancelRecurringGiftModalContainer>
                {STEPS_MAP[step]({
                    onChangePage: setStep,
                    closeModal: () => {
                        closeModal()
                    }
                })}
            </StyledCancelRecurringGiftModalContainer>
        </Modal>
    )
}

const CancelRecurringGiftModalStepOne: FC<StepProps> = ({onChangePage, closeModal}) => {
    const {t} = useTranslation()
    const {hostID} = useRootStore(selectCancelRecurringGiftModal)
    const {mutate: sendGift, error: ErrorSendGift} = useSendGift()
    const {data: amountsList} = useFetchSubscribedAmounts(false)
    const {data: subscribedHosts} = useFetchSubscribedHosts({
        enabled: true,
        status: SubscriptionStatusesE.ACTIVE
    })
    const activeSubscribedHost = subscribedHosts?.find(({rookie_id}) => rookie_id.toString() === hostID)
    const limitedAmountsList = amountsList?.filter(({amount}) => amount < Number(activeSubscribedHost?.amount))
    const {data: host} = useGetHost({filter: `${hostID}`})
    const usersFirstName = host?.type_attributes.first_name
    const {openModal: openChoosePaymentsMethodModal} = useRootStore(selectChoosePaymentsMethodModal)
    const {openErrorModal} = useRootStore(selectErrorModal)

    const onSubmit = () => {
        if (!!selectedAmount) {
            sendGift({hostID: String(hostID), subscriptionId: `${selectedAmount?.id}`})
            closeModal()
        } else if (!limitedAmountsList?.length) {
            closeModal()
        }
    }

    useEffect(() => {
        if (ErrorSendGift) {
            if (ErrorSendGift.response?.status === 303) {
                openChoosePaymentsMethodModal({
                    paymentMethodUrls: ErrorSendGift.response?.data?.url ?? null,
                    description: 'choose_payment_method:description_micromorgi'
                })
            } else {
                openErrorModal({message: ErrorSendGift.message || null})
            }
        }
    }, [ErrorSendGift])

    const [selectedAmount, setSelectedAmount] = useState<DonationType | null>(null)
    const isDisabledButton = !selectedAmount && !!limitedAmountsList?.length
    return (
        <>
            <StyledCancelRecurringGiftModalDescription>
                {t('cancel_rookie_gift:first_step:description', {USER: usersFirstName})}
            </StyledCancelRecurringGiftModalDescription>
            <StyledLimitedAmountsList>
                {limitedAmountsList?.map(limitedAmount => (
                    <AmountItem
                        key={limitedAmount.id}
                        amount={limitedAmount}
                        isSelected={selectedAmount?.id === limitedAmount.id}
                        onSelect={() => setSelectedAmount(limitedAmount)}
                        amountIcon={<MorgiIcon height={31} width={31} />}
                    />
                ))}
            </StyledLimitedAmountsList>
            <StyledRejectButton onClick={onSubmit} disabled={isDisabledButton}>
                {t('cancel_rookie_gift:first_step:reject_button', {USER: usersFirstName})}
            </StyledRejectButton>
            <StyledConfirmButton
                onClick={() => {
                    onChangePage(2)
                }}
                variant="outlined"
            >
                {t('cancel_rookie_gift:first_step:confirm_button')}
            </StyledConfirmButton>
        </>
    )
}

const CancelRecurringGiftModalStepSecond: FC<StepProps> = ({onChangePage}) => {
    const {t} = useTranslation()
    const {hostID, closeModal} = useRootStore(selectCancelRecurringGiftModal)
    const {data: host} = useGetHost({filter: String(hostID)})
    const {mutate: deleteRecurringGift, data: dataOfDeleteRecurringGift} = useDeleteGift(String(hostID))
    const usersFirstName = host?.type_attributes.first_name
    const onCancelSupport = () => {
        deleteRecurringGift()
    }
    useEffect(() => {
        if (dataOfDeleteRecurringGift) onChangePage(3)
    }, [dataOfDeleteRecurringGift])

    return (
        <>
            <StyledCancelRecurringGiftModalStepSecondContainer>
                <StyledHostsAvatar $src={String(host?.avatar?.url)} />
                <StyledCancelRecurringGiftModalStepSecondTitle>
                    {t('cancel_rookie_gift:second_step:title')}
                </StyledCancelRecurringGiftModalStepSecondTitle>

                <StyledCancelRecurringGiftModalStepSecondDescription>
                    <Trans
                        t={t}
                        i18nKey={`cancel_rookie_gift:second_step:description_${host?.gender?.key_name}`}
                        components={{u: <u />}}
                        values={{
                            USER: String(usersFirstName)
                        }}
                    />
                </StyledCancelRecurringGiftModalStepSecondDescription>
            </StyledCancelRecurringGiftModalStepSecondContainer>
            <StyledButtonContainer>
                <StyledRejectButton onClick={closeModal}>
                    {t('cancel_rookie_gift:second_step:reject_button')}
                </StyledRejectButton>
                <StyledCancelButton onClick={onCancelSupport} variant="transparent">
                    {t('cancel_rookie_gift:second_step:confirm_button')}
                </StyledCancelButton>
            </StyledButtonContainer>
        </>
    )
}

const CancelRecurringGiftModalStepThird: FC<StepProps> = ({closeModal}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {data: user} = useMe()
    const {hostID} = useRootStore(selectCancelRecurringGiftModal)
    const {data: subscribedHosts} = useFetchSubscribedHosts({
        enabled: true,
        status: SubscriptionStatusesE.ACTIVE
    })
    const {data: host} = useGetHost({filter: String(hostID)})
    const activeSubscribedHost = subscribedHosts?.find(({rookie_id}) => rookie_id.toString() === hostID)
    const usersFirstName = host?.type_attributes.first_name

    if (!user) return <></>

    return (
        <StyledCancelRecurringGiftModalStepThirdContainer>
            <StyledAnimationBox>
                <Animation animationData={Cry} />
            </StyledAnimationBox>
            <StyledCancelRecurringGiftModalStepThird>
                <StyledCancelRecurringGiftModalStepThirdTitle>
                    {t('cancel_rookie_gift:status_step:title')}
                </StyledCancelRecurringGiftModalStepThirdTitle>
                <StyledCancelRecurringGiftModalStepThirdDescription>
                    {t('cancel_rookie_gift:status_step:description', {
                        USER: usersFirstName,
                        DATE: activeSubscribedHost?.valid_until_at
                            ? getLocalDate(String(activeSubscribedHost.valid_until_at), 'DD MMMM')
                            : ''
                    })}
                </StyledCancelRecurringGiftModalStepThirdDescription>
            </StyledCancelRecurringGiftModalStepThird>

            <StyledMeetButton
                onClick={() => {
                    closeModal()
                    navigate(REDIRECT_MAP[user.type])
                }}
            >
                {t('cancel_rookie_gift:status_step:button')}
            </StyledMeetButton>
        </StyledCancelRecurringGiftModalStepThirdContainer>
    )
}
