import styled, {css} from 'styled-components'

export const StyledCardWrapper = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 88px;
        border-radius: 16px;
        background: ${palette.neutral.white};
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
        padding: 0 ${spacing * 2.75}px 0 ${spacing * 1.25}px;
        cursor: pointer;
    `}
`

export const StyledCardContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    & p {
        margin: 0;
    }
`

export const StyledCardContentImages = styled.div`
    width: 69px;
    height: 45px;

    & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`

export const StyledCardContentTitle = styled.div`
    ${({theme: {spacing}}) => css`
        margin-left: ${spacing * 1.5}px;
    `}
`

export const StyledMainTitle = styled.p`
    ${({theme: {palette, typography}}) => css`
        ${typography.textLg}
        font-weight: 600;
        color: ${palette.primary[900]};
        text-align: start;
    `}
`

export const StyledSubTitle = styled.p`
    ${({theme: {palette, typography}}) => css`
        ${typography.textXs}
        font-weight: 500;
        color: ${palette.primary[700]};
        text-align: start;
    `}
`
