import axios from 'axios'

import i18n, {FALLBACK_LANGUAGE} from '@/translations/i18n.ts'

// create axios custom instance with custom config
const clientAPI = axios.create({
    baseURL: 'https://morgi-backend-v2.backend.k-stage.dev',
    withCredentials: true, // needed for http-only cookie
    headers: {
        common: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        },
        post: {
            'Access-Control-Allow-Origin': '*'
        }
    }
})

clientAPI.interceptors.request.use(config => {
    config.headers['X-localization'] = i18n.language || FALLBACK_LANGUAGE
    // TODO refactor BE; remove after implemented
    const token = localStorage.getItem('bearer_access_token_v2')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
})

//const endpointToIgnore = [commonRoutes.LOGIN_GUEST.path, commonRoutes.LOGIN_HOST.path]

clientAPI.interceptors.response.use(
    response => {
        // pass response without change
        return response
    },
    error => {
        // get error info
        const statusCode = error?.response?.status
        //const originalRequest = error.config
        console.log('error', error)
        switch (statusCode) {
            case 401: {
                // TODO: validate this against backend
                /*if (!originalRequest._retry && !endpointToIgnore.includes(error.config.url)) {
                    originalRequest._retry = true // prevent infinite retry loop
                    await clientAPI.post('/auth/refresh-token')
                    return clientAPI.request(originalRequest)
                }*/
                return Promise.reject(error)
            }
            default:
                return Promise.reject(error)
        }
    }
)

export default clientAPI
