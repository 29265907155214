import {useMe} from '@/features/authentication/queries/useMe'
import {GuestType} from '@/features/authentication/types'
import {remapCurrency} from '@/features/profile/constants'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {useRootStore} from '@/store'
import {selectChoosePaymentsMethodModal} from '@/store/selectors'
import {USER_ROLE_GUEST} from '@/utilities/constants/user'
import {useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {CreditCardTypeE, CreditCardsNameE} from '../../types'
import ActivePaymentMethod from '../active-payment-method/ActivePaymentMethod'
import CreditCardsList from '../credit-cards-list/CreditCardsList'
import {
    StyledAddOtherMethod,
    StyledBoldDescription,
    StyledChangePaymentMethodsButton,
    StyledContentWrapper,
    StyledDescription,
    StyledModal
} from './style'

const ChoosePaymentMethodModal = () => {
    const {t} = useTranslation()
    const {height} = useWindowDimensions()
    const {
        rookieName,
        currencyAmount,
        morgisAmount,
        paymentMethodUrls,
        description,
        isRecurring,
        // isBuyingPackage,
        // isFetchingPackages,
        closeModal
    } = useRootStore(selectChoosePaymentsMethodModal)

    const {data} = useMe()
    const [praxisDataForIframe, setPraxisDataForIframe] = useState<string | null | undefined>(null)

    const user = data?.type === USER_ROLE_GUEST ? (data as GuestType) : null

    const activeCreditCard = user?.type_attributes?.preferred_payment_method
        ? user.type_attributes.preferred_payment_method.last4
        : null

    useEffect(() => {
        setPraxisDataForIframe(paymentMethodUrls?.[CreditCardsNameE.PRAXIS_GENERIC])
    }, [])

    const onPaymentMethodClick = (type: CreditCardTypeE, url: string) => {
        if (type === CreditCardTypeE.CCBILL) {
            window.location.replace(url)
        } else if (type === CreditCardTypeE.PRAXIS) {
            setPraxisDataForIframe(url)
        }
    }

    return (
        <StyledModal title={t('choose_payment_method:title')} onClose={closeModal} vh={height}>
            <StyledDescription>
                {description && (
                    <Trans
                        t={t}
                        i18nKey={description}
                        components={{
                            span: <StyledBoldDescription />
                        }}
                        values={{
                            ROOKIE: rookieName,
                            AMOUNT: `${remapCurrency[user?.currency || 'USD']}${currencyAmount}`,
                            MORGIS: morgisAmount
                        }}
                    />
                )}

                <StyledContentWrapper>
                    {!isRecurring && !!user?.type_attributes?.preferred_payment_method && (
                        <StyledChangePaymentMethodsButton>
                            {t('choose_payment_method:change_payment_method')}
                        </StyledChangePaymentMethodsButton>
                    )}
                    {(isRecurring || !user?.type_attributes?.preferred_payment_method) && (
                        <CreditCardsList
                            paymentMethodUrls={paymentMethodUrls}
                            activeMethodType={user?.type_attributes?.preferred_payment_method?.platform}
                            onPaymentMethodClick={onPaymentMethodClick}
                        />
                    )}
                    {isRecurring && (
                        <ActivePaymentMethod
                            activeCreditCard={activeCreditCard}
                            onClick={onPaymentMethodClick}
                            url={paymentMethodUrls ? paymentMethodUrls[CreditCardsNameE.PRAXIS_CREDIT_CARD] : undefined}
                            type={user?.type_attributes?.preferred_payment_method?.platform}
                        />
                    )}
                    {!!praxisDataForIframe && (
                        <>
                            {/* <StyledOtherMethodDescription>{praxisDataForIframe.title}</StyledOtherMethodDescription> */}
                            <StyledAddOtherMethod>
                                <iframe src={praxisDataForIframe} height={'100%'} width={'100%'}></iframe>
                            </StyledAddOtherMethod>
                        </>
                    )}
                </StyledContentWrapper>
            </StyledDescription>
        </StyledModal>
    )
}

export default ChoosePaymentMethodModal
