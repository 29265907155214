import {Trans, useTranslation} from 'react-i18next'
import {ConfirmIcon} from '@/assets/icons/icons'
import {StyledConfirmIcon, StyledOption, StyledOptionText} from './style'
import useUnreadMessagesCountReps from '@/features/representative/hooks/useUnreadMessageCountReps'
import {HostPartialType} from '@/features/authentication/types'
import {ChannelsType} from '@/features/representative/types'

type ChatHostOptionProps = {
    onClick: () => void
    host: HostPartialType & {hostChannels?: ChannelsType}
    countRepsUnreadMessages: (num: number | null)=> void
    selectedHostId?: number
}

export const ChatHostOption = ({onClick, host, countRepsUnreadMessages, selectedHostId}: ChatHostOptionProps) => {
    const {t} = useTranslation()
    const count = useUnreadMessagesCountReps({host, countRepsUnreadMessages})

    return (
        <StyledOption key={host.id} onClick={onClick}>
            <StyledConfirmIcon checked={selectedHostId === host.id}>
                <ConfirmIcon />
            </StyledConfirmIcon>

            <StyledOptionText>
                <Trans t={t} i18nKey={host?.username} components={{span: <span />}} />
            </StyledOptionText>

            {!!count && <StyledOptionText>({count})</StyledOptionText>}
        </StyledOption>
    )
}
