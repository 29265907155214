import {useTranslation} from 'react-i18next'
import {Popover} from '@/components/ui/popover/Popover'
import {useEffect, useMemo, useState} from 'react'
import {StyledEmptySection, StyledHostSelectContainer, StyledHostSelectRoot} from './style'
import {ChatButton} from '../chat-button/ChatButton'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {LocalStorageManager} from '@/utilities/localStorage'
import {useRootStore} from '@/store'
import {selectChatStore} from '@/store/selectors'
import {useGetRepresentedUser} from '@/hooks/useGetRepresentedHost'
import {useNavigate} from 'react-router-dom'
import {ROUTE_MESSAGE_CENTER} from '@/utilities/constants/routeNames'
import {useGetRepresentativeHostsChannels} from '@/features/representative/queries/useGetRepresentativeHostsChannels'
import {ChatHostOption} from './ChatHostOption'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/features/representative/queries/keys.ts'

type ChatHostsSelectProps = {
    isLight: boolean
    isAgent: boolean
    countRepsUnreadMessages: (num: number | null)=> void
}

export const ChatHostsSelect = ({isLight, isAgent, countRepsUnreadMessages}: ChatHostsSelectProps) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {setNeedReInitAfterChangedRepresentedHost} = useRootStore(selectChatStore)
    const {isLoading, agentAndRepsHosts: hosts, representedUserId} = useGetRepresentedUser()
    const [isOpen, setIsOpen] = useState(false)
    const [selectedHostId, setSelectedHostId] = useState<number | undefined>(representedUserId)
    const queryClient = useQueryClient()

    const {data: hotsChannels} = useGetRepresentativeHostsChannels(!isAgent)

    const correctHosts = useMemo(() => {
        if (isAgent) {
            return hosts
        } else {
            return hosts.length
                ? hosts.map(host => {
                      return {
                          ...host,
                          hostChannels: hotsChannels[`${host.id}`]
                      }
                  })
                : []
        }
    }, [hotsChannels, hosts])

    useEffect(() => {
        if (!selectedHostId) {
            setSelectedHostId(representedUserId)
        }
    }, [representedUserId])

    return (
        <StyledHostSelectContainer>
            <Popover
                trigger={<ChatButton isLight={isLight} />}
                rootProps={{
                    open: isOpen,
                    onOpenChange: () => {
                        setIsOpen(!isOpen)
                        !isOpen && queryClient.invalidateQueries({queryKey: [QUERY_KEYS.representative_hosts_channels]})
                    }
                }}
                isModal
            >
                <StyledHostSelectRoot>
                    {isLoading ? (
                        <Spinner />
                    ) : correctHosts?.length ? (
                        correctHosts.map(host => {
                            return (
                                <ChatHostOption
                                    key={host.id}
                                    onClick={() => {
                                        setIsOpen(false)
                                        LocalStorageManager.chatUserId.set(host.id)
                                        setSelectedHostId(host.id)
                                        setNeedReInitAfterChangedRepresentedHost(true)
                                        navigate(ROUTE_MESSAGE_CENTER)
                                    }}
                                    host={host}
                                    selectedHostId={selectedHostId}
                                    countRepsUnreadMessages={countRepsUnreadMessages}
                                />
                            )
                        })
                    ) : (
                        <StyledEmptySection>{t('common:no_options')}</StyledEmptySection>
                    )}
                </StyledHostSelectRoot>
            </Popover>
        </StyledHostSelectContainer>
    )
}
