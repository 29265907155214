import {OptionType} from '@/components/commons/singleSelect/types'
import {SocialLoginError} from '@/features/authentication/queries/useSocialLogin'
import {ChatTopicsSchema, FavoriteActivitiesSchema} from '@/features/authentication/services/auth.schema'
import {GenderResponse} from '@/features/authentication/types'
import {CountriesWithLanguageResponseType, LanguagesResponseType} from '@/features/country/types'
import {UserRoleE} from '@/types'
import {z} from 'zod'

type ChatTopicsType = z.infer<typeof ChatTopicsSchema>
type FavoriteActivitiesType = z.infer<typeof FavoriteActivitiesSchema>

// Generic event type
type MixpanelTrackEventSchema<TName, TProps = undefined> = {
    name: TName
    properties?: TProps
}

// EVENT NAMES
export enum MixpanelEventNameE {
    SignUpHost = 'signup_rookie',
    SignUpGuest = 'signup_leader',
    PageView = 'page_view',
    SignupGuestError = 'signup_leader_error',
    SignUpGuestFirstStepSuccess = 'signup_leader_step_1_success',
    ConnectTelegramClick = 'connect_telegram_click',
    DeleteAccountClick = 'delete_account_click',
    DeleteAccountCancel = 'delete_account_cancel',
    GiftAmountClick = 'gift_amount_click',
    MicromorgiAmountSelect = 'micromorgi_amount_select',
    GiftMicromorgiError = 'gift_micromorgi_error',
    ConverterSentLink = 'converter_sent_link',
    GuestReceivedLink = 'leader_received_link',
    GuestClickedOnLink = 'friend_clicked_on_link',
    PausedConnectionSuccess = 'paused_connection_success',
    SentMessageSuccess = 'sent_message_success',
    SentMediaPhotoSuccess = 'sent_media_photo_success',
    SentMediaVideoSuccess = 'sent_media_video_success',
    PauseConnectionClick = 'pause_connection_click',
    BlockUserSuccess = 'block_user_success',
    GiftMicromorgisClick = 'gift_micromorgis_click',
    BuyMicromorgisClick = 'buy_micromorgis_click',
    GiftMorgiClick = 'gift_morgi_click',
    OpenConnectionClick = 'open_connection_click',
    OpenConnectionSuccess = 'open_connection_success',
    BlockUserClick = 'block_user_click',
    CheckoutContinue = 'checkout_continue',
    JoinTelegramClick = 'join_telegram_click',
    ProfileModalOpen = 'profile_modal_open',
    ProfileModalClose = 'profile_modal_close',
    UncoverContentSentHostSide = 'uncover_content_sent_host_side',
    UncoverContentPaidGuestSide = 'uncover_content_paid_guest_side',
    UserClickedOnGoalTeaserOnFeed = 'user_clicked_on_goal_teaser_on_feed',
    UserClickedOnTelegramTeaser = 'user_clicked_on_telegram_teaser',
    LinkBlockedFromSending = 'link_blocked_from_sending',
    PhoneBlockedFromSending = 'phone_blocked_from_sending',
    ContinuedToMorgi = 'continued_to_morgi',
    CreatedGoal = 'created_goal',
    GoalTimeChanged = 'goal_time_changed',
    GoalCancelled = 'goal_cancelled',
    GoalFilterApplied = 'goal_filter_applied',
    AnimationSend = 'animation_send',
    FilterApplied = 'filter_applied',
    ProofSent = 'proof_sent',
    OnboardedGuest = 'onboarded_guest',
    BecameTopFan = 'became_top_fan',
    ClickedOnHostLeaderbord = 'clicked_on_host_leaderbord',
    BroadcastMessageSent = 'broadcast_message_sent',
    MegaGiftSent = 'mega_gift_sent',
    VerifyEmailMagicLinkClick = 'verify_email_magic_link_click'
}

// EVENT TYPES
type SignUpHostEvent = MixpanelTrackEventSchema<MixpanelEventNameE.SignUpHost>
type SignUpGuestEvent = MixpanelTrackEventSchema<MixpanelEventNameE.SignUpGuest>
type SignUpGuestFirstStepSuccessEvent = MixpanelTrackEventSchema<
    MixpanelEventNameE.SignUpGuestFirstStepSuccess,
    {added_self_description: string}
>
type PageViewEvent = MixpanelTrackEventSchema<MixpanelEventNameE.PageView, {page_name: string}>
type SignUpGuestErrorEvent = MixpanelTrackEventSchema<
    MixpanelEventNameE.SignupGuestError,
    {error?: SocialLoginError; formStep: string}
>
type ConnectTelegramClickEvent = MixpanelTrackEventSchema<
    MixpanelEventNameE.ConnectTelegramClick,
    {
        clicked_on?: string
    }
>
type DeleteAccountClickEvent = MixpanelTrackEventSchema<MixpanelEventNameE.DeleteAccountClick>
type DeleteAccountCancelEvent = MixpanelTrackEventSchema<MixpanelEventNameE.DeleteAccountCancel, {screen: string}>
type GiftAmountClickEvent = MixpanelTrackEventSchema<MixpanelEventNameE.GiftAmountClick, {amountSelected: number}>
type MicromorgiAmountSelectEvent = MixpanelTrackEventSchema<
    MixpanelEventNameE.MicromorgiAmountSelect,
    {amount_selected: number}
>
type GiftMicromorgiErrorEvent = MixpanelTrackEventSchema<
    MixpanelEventNameE.GiftMicromorgiError,
    {error_type: string; micro_morgi_gift_amount?: number; custom: string}
>
type ConverterSentLinkEvent = MixpanelTrackEventSchema<MixpanelEventNameE.ConverterSentLink, {link_url: string}>
type GuestReceivedLinkEvent = MixpanelTrackEventSchema<MixpanelEventNameE.GuestReceivedLink, {link_url: string}>
type GuestClickedOnLinkEvent = MixpanelTrackEventSchema<MixpanelEventNameE.GuestClickedOnLink, {link_url: string}>
type PausedConnectionSuccessEvent = MixpanelTrackEventSchema<MixpanelEventNameE.PausedConnectionSuccess>
type SentMessageSuccessEvent = MixpanelTrackEventSchema<MixpanelEventNameE.SentMessageSuccess>
type SentMediaPhotoSuccessEvent = MixpanelTrackEventSchema<MixpanelEventNameE.SentMediaPhotoSuccess>
type SentMediaVideoSuccessEvent = MixpanelTrackEventSchema<MixpanelEventNameE.SentMediaVideoSuccess>
type PauseConnectionClickEvent = MixpanelTrackEventSchema<MixpanelEventNameE.PauseConnectionClick>
type BlockUserSuccessEvent = MixpanelTrackEventSchema<MixpanelEventNameE.BlockUserSuccess>
type GiftMicromorgisClickEvent = MixpanelTrackEventSchema<MixpanelEventNameE.GiftMicromorgisClick>
type BuyMicromorgisClickEvent = MixpanelTrackEventSchema<MixpanelEventNameE.BuyMicromorgisClick>
type GiftMorgiClickEvent = MixpanelTrackEventSchema<MixpanelEventNameE.GiftMorgiClick>
type OpenConnectionClickEvent = MixpanelTrackEventSchema<
    MixpanelEventNameE.OpenConnectionClick,
    ProfileEventsPropsType | undefined
>
type OpenConnectionSuccessEvent = MixpanelTrackEventSchema<
    MixpanelEventNameE.OpenConnectionSuccess,
    ProfileEventsPropsType | undefined
>
type BlockUserClickEvent = MixpanelTrackEventSchema<MixpanelEventNameE.BlockUserClick>
type CheckoutContinueEvent = MixpanelTrackEventSchema<MixpanelEventNameE.CheckoutContinue>
type JoinTelegramClickEvent = MixpanelTrackEventSchema<MixpanelEventNameE.JoinTelegramClick>
type ProfileModalOpenEvent = MixpanelTrackEventSchema<
    MixpanelEventNameE.ProfileModalOpen,
    {
        watched_video: boolean
        scrolled_images: number
    }
>
type ProfileModalCloseEvent = MixpanelTrackEventSchema<
    MixpanelEventNameE.ProfileModalClose,
    {
        watched_video: boolean
        scrolled_images: number
    }
>
type UncoverContentSentHostSideEvent = MixpanelTrackEventSchema<
    MixpanelEventNameE.UncoverContentSentHostSide,
    {file_type: string; morgi_amount: number; guest_id: string}
>
type UncoverContentPaidGuestSideEvent = MixpanelTrackEventSchema<
    MixpanelEventNameE.UncoverContentPaidGuestSide,
    {file_type: string; morgi_amount?: string | number; host_id: string}
>
type UserClickedOnGoalTeaserOnFeedEvent = MixpanelTrackEventSchema<
    MixpanelEventNameE.UserClickedOnGoalTeaserOnFeed,
    {teasers_seen_by_user_lifetime: string | null; teaser_color: string}
>
type UserClickedOnTelegramTeaserEvent = MixpanelTrackEventSchema<
    MixpanelEventNameE.UserClickedOnTelegramTeaser,
    {teasers_seen_by_user_lifetime: string | null}
>
type LinkBlockedFromSendingEvent = MixpanelTrackEventSchema<
    MixpanelEventNameE.LinkBlockedFromSending,
    {link_url: string}
>
type PhoneBlockedFromSendingEvent = MixpanelTrackEventSchema<
    MixpanelEventNameE.PhoneBlockedFromSending,
    {message_sent: string}
>
type ContinuedToMorgiEvent = MixpanelTrackEventSchema<MixpanelEventNameE.ContinuedToMorgi>
type CreatedGoalEvent = MixpanelTrackEventSchema<MixpanelEventNameE.CreatedGoal, GoalEventsPropsType>
type GoalTimeChangedEvent = MixpanelTrackEventSchema<MixpanelEventNameE.GoalTimeChanged, GoalEventsPropsType>
type GoalCancelledEvent = MixpanelTrackEventSchema<MixpanelEventNameE.GoalCancelled, GoalEventsPropsType>
type GoalFilterAppliedEvent = MixpanelTrackEventSchema<
    MixpanelEventNameE.GoalFilterApplied,
    {goal_type: string; path: string[]}
>
type AnimationSendEvent = MixpanelTrackEventSchema<
    MixpanelEventNameE.AnimationSend,
    {animation: number; morgi_amount: number; guest_id: number}
>
type FilterAppliedEvent = MixpanelTrackEventSchema<
    MixpanelEventNameE.FilterApplied,
    {
        chat_topics: ChatTopicsType[]
        activities: FavoriteActivitiesType[]
        countries: CountriesWithLanguageResponseType
        languages: LanguagesResponseType
        gender: GenderResponse
        special_host_category: OptionType[]
        age_range: number[]
    }
>
type ProofSentEvent = MixpanelTrackEventSchema<
    MixpanelEventNameE.ProofSent,
    {
        proof_type: string
    }
>
type OnboardedGuestEvent = MixpanelTrackEventSchema<
    MixpanelEventNameE.OnboardedGuest,
    {
        onboarding_type: string
    }
>
type BecameTopFanEvent = MixpanelTrackEventSchema<
    MixpanelEventNameE.BecameTopFan,
    {
        value_invested: number
        host_username: string
    }
>
type ClickedOnHostLeaderbordEvent = MixpanelTrackEventSchema<
    MixpanelEventNameE.ClickedOnHostLeaderbord,
    {
        value_invested: number
        host_username?: string
    }
>
type BroadcastMessageSentEvent = MixpanelTrackEventSchema<MixpanelEventNameE.BroadcastMessageSent>
type MegaGiftSentEvent = MixpanelTrackEventSchema<MixpanelEventNameE.MegaGiftSent>
type VerifyEmailMagicLinkClickEvent = MixpanelTrackEventSchema<MixpanelEventNameE.VerifyEmailMagicLinkClick>

export type MixpanelTrackEvent =
    | SignUpHostEvent
    | SignUpGuestEvent
    | SignUpGuestFirstStepSuccessEvent
    | PageViewEvent
    | SignUpGuestErrorEvent
    | ConnectTelegramClickEvent
    | DeleteAccountClickEvent
    | DeleteAccountCancelEvent
    | GiftAmountClickEvent
    | MicromorgiAmountSelectEvent
    | GiftMicromorgiErrorEvent
    | ConverterSentLinkEvent
    | GuestReceivedLinkEvent
    | GuestClickedOnLinkEvent
    | PausedConnectionSuccessEvent
    | SentMessageSuccessEvent
    | SentMediaPhotoSuccessEvent
    | SentMediaVideoSuccessEvent
    | PauseConnectionClickEvent
    | BlockUserSuccessEvent
    | GiftMicromorgisClickEvent
    | BuyMicromorgisClickEvent
    | GiftMorgiClickEvent
    | OpenConnectionClickEvent
    | OpenConnectionSuccessEvent
    | BlockUserClickEvent
    | CheckoutContinueEvent
    | JoinTelegramClickEvent
    | ProfileModalOpenEvent
    | ProfileModalCloseEvent
    | UncoverContentSentHostSideEvent
    | UncoverContentPaidGuestSideEvent
    | UserClickedOnGoalTeaserOnFeedEvent
    | UserClickedOnTelegramTeaserEvent
    | LinkBlockedFromSendingEvent
    | PhoneBlockedFromSendingEvent
    | ContinuedToMorgiEvent
    | CreatedGoalEvent
    | GoalTimeChangedEvent
    | GoalCancelledEvent
    | GoalFilterAppliedEvent
    | AnimationSendEvent
    | FilterAppliedEvent
    | ProofSentEvent
    | OnboardedGuestEvent
    | BecameTopFanEvent
    | ClickedOnHostLeaderbordEvent
    | BroadcastMessageSentEvent
    | MegaGiftSentEvent
    | VerifyEmailMagicLinkClickEvent

export type SuperPropsObjType = {
    adID?: string
}

type MixpanelData<TName extends MixpanelTrackEvent['name']> = Extract<MixpanelTrackEvent, {name: TName}>['properties']

export type TriggerBackendEventParamsType<TName extends MixpanelTrackEvent['name']> = {
    type: TName
    leader_id?: string
    rookie_id?: string
    data: MixpanelData<TName> | null
}

export type MixpanelType = {
    identifyUser: (userID: string) => void
    trackEvent: <TName extends MixpanelTrackEvent['name']>(
        eventName: TName,
        eventProperties?: Extract<MixpanelTrackEvent, {name: TName}>['properties']
    ) => void
    setSuperPropsOnce: (superPropsObj: SuperPropsObjType) => void
    startTimeEvent: <TName extends MixpanelTrackEvent['name']>(eventName: TName) => void
    reset: () => void
    triggerBackendEvent: <TName extends MixpanelTrackEvent['name']>({
        eventType,
        userRole,
        userId,
        mixpanelData
    }: {
        eventType: MixpanelEventNameE
        userRole?: UserRoleE
        userId?: string
        mixpanelData?: Extract<MixpanelTrackEvent, {name: TName}>['properties'] | undefined
    }) => void
}

export type MixPanelContextType = {
    mixpanel: MixpanelType
}

export type ProfileEventsPropsType = {
    carousel_card_number?: number
    country_filter_applied?: string[] | string // country || 'No'
    from: string // 'Profile' || 'Carousel'
    watched_video?: boolean
    click_share: boolean
    scrolled_images?: number
    time_on_profile: number
    common_chat_topics?: string[]
    common_activties?: string[]
}

export type GoalEventsPropsType = {
    goal_name: string
    goal_amount: string
    goal_type: string
    goal_end_date: string
    proof_type: {
        has_image_proof: boolean
        has_video_proof: boolean
    }
    images_count: number
    videos_count: number
    number_of_supporters?: number
    time?: string
    days_since_start?: number
    days_to_end?: number
    new_end_date?: string
    cancelled_reason?: string | null
}
