import {TopicSchema} from '@/features/chat-fav-topics/services/chatFavTopics.schema.ts'
import {GenderKeyNameE, UserStatusesE} from '@/features/constants/constants'
import {CountrySchema, CountryWithLanguageSchema, LanguageSchema} from '@/features/country/services/country.schema'
import {CreditCardTypeE, SocialPlatformTypeE} from '@/features/guest-payments/types'
import {IdCardStatusesE} from '@/utilities/constants/user'
import {z} from 'zod'
import {AlertTypeE} from '../types'
import {UserRoleE} from '@/types'

const UserStatuses = z.nativeEnum(UserStatusesE)
const GenderKeyNames = z.nativeEnum(GenderKeyNameE)

export const ChatTopicsSchema = z.object({
    id: z.number(),
    name: z.string(),
    key_name: z.string(),
    is_popular: z.boolean()
})

export const FavoriteActivitiesSchema = z.object({
    id: z.number(),
    name: z.string(),
    key_name: z.string(),
    is_popular: z.boolean()
})

export const SocialsSchema = z.object({
    id: z.number(),
    followers_amount: z.string(),
    label: z.string(),
    social_url: z.string().nullable().optional(),
    social_media: z.object({
        id: z.number(),
        key_name: z.string(),
        name: z.string()
    })
})

export const SpokenLanguagesSchema = z.object({
    id: z.number(),
    name: z.string(),
    code: z.string(),
    is_translatable: z.boolean()
})

export const GenderSchema = z.object({
    id: z.number(),
    name: z.string(),
    key_name: GenderKeyNames
})

export const GuestTypeAttributesSchema = z.object({
    id: z.number(),
    coupons: z.array(z.unknown()),
    has_converter_chat: z.boolean(),
    has_approved_transaction: z.boolean(),
    micro_morgi_balance: z.number(),
    total_coupons_got: z.number(),
    carousel_type: z.string(),
    level_status: z.string(),
    next_level_amount: z.number().nullable(),
    top_fans_count: z.number(),
    leaders_carousels_filters: z
        .object({
            leader_id: z.number(),
            filters: z.object({
                chatTopics: z.array(TopicSchema),
                activities: z.array(TopicSchema),
                countries: z.array(CountryWithLanguageSchema),
                languages: z.array(LanguageSchema),
                genders: z.array(GenderSchema),
                fromAge: z.number().nullable(),
                toAge: z.number().nullable(),
                specialHostCategory: z
                    .array(
                        z.object({
                            value: z.number(),
                            label: z.string(),
                            data: z.object({
                                key_name: z.string(),
                                category: z.string()
                            })
                        })
                    )
                    .nullable()
                    .optional()
            })
        })
        .nullable(),
    has_supported_goal: z.boolean(),
    wish: z
        .array(z.object({id: z.number(), name: z.string(), key_name: z.string()}))
        .optional()
        .nullable(),
    has_ever_paid: z.boolean(),
    can_open_connection: z.boolean(),
    connections_opened_today: z.number(),
    can_send_messages: z.boolean(),
    messages_sent_today: z.number(),
    active_credit_card: z
        .object({
            cardType: z.string().nullable(),
            last4: z.number().nullable(),
            expDate: z.string().nullable()
        })
        .nullable(),
    has_new_credit_card: z.boolean(),
    is_first_transaction: z.boolean(),
    interested_in_gender: GenderSchema.nullable(),
    ultimate_offer: z.object({
        is_ultimate_status_offer_available: z.boolean(),
        end_date: z.union([z.date(), z.string()]).nullable()
    }),
    memories_count: z.number(),
    next_dollar_amount: z.number().nullable(),
    is_top_fans_badge_visible: z.boolean(),
    preferred_payment_method: z
        .object({
            platform: z.nativeEnum(CreditCardTypeE),
            system_name: z.string(),
            gateway_type: z.string(),
            gateway_hash: z.string().nullable(),
            last4: z.union([z.number().nullable(), z.string().nullable()])
        })
        .nullable()
        .optional(),
    show_feed_additions: z.boolean().optional(),
    cashback_offer: z
        .object({
            is_available_cashback_offer: z.boolean(),
            end_date: z.union([z.date(), z.string()]).nullable()
        })
        .optional()
})

export const FeaturesGuestsSchema = z.array(
    z.object({
        id: z.number(),
        type: z.enum(['leader', 'rookie', 'representative']),
        full_name: z.string(),
        username: z.string(),
        description: z.string().nullable(),
        is_online: z.boolean(),
        pubnub_uuid: z.string(),
        created_at: z.string(),
        chat_is_online: z.boolean(),
        has_verified_id: z.boolean(),
        spoken_languages: z.array(SpokenLanguagesSchema),
        gender: GenderSchema.nullable(),
        avatar: z
            .object({
                id: z.number(),
                created_at: z.string(),
                main: z.boolean(),
                path_location: z.string(),
                under_validation: z.boolean(),
                url: z.string().url(),
                user_id: z.number()
            })
            .nullable(),
        video: z
            .object({
                id: z.number(),
                user_id: z.number(),
                url: z.string(),
                path_location: z.string(),
                is_processed: z.number().optional(),
                created_at: z.string(),
                under_validation: z.boolean()
            })
            .nullable()
            .optional(),
        type_attributes: z.object({
            id: z.number(),
            level_status: z.string().optional(),
            interested_in_gender: GenderSchema.optional().nullable(),
            has_ever_paid: z.boolean().optional(),
            wish: z
                .array(
                    z.object({
                        id: z.number(),
                        name: z.string(),
                        key_name: z.string()
                    })
                )
                .nullable()
                .optional()
        }),
        chat_topics: z.array(ChatTopicsSchema),
        favorite_activities: z.array(FavoriteActivitiesSchema),
        //TODO fix when user object will be fix
        // socials: z.array(SocialsSchema)
        socials: z.any()
    })
)

export const HostsAgentSchema = z.object({
    id: z.number(),
    avatar: z
        .object({
            id: z.number(),
            user_id: z.number(),
            path_location: z.string(),
            main: z.boolean(),
            is_face_recognition_processed: z.boolean(),
            title: z.string().nullable(),
            content: z.string().nullable(),
            tags: z.string().nullable(),
            created_at: z.string(),
            updated_at: z.string(),
            url: z.string(),
            under_validation: z.boolean()
        })
        .nullable()
        .optional(),
    first_name: z.string(),
    last_name: z.string()
})

export const HostTypeAttributesScheme = z.object({
    is_influencer: z.boolean().optional(),
    agent: HostsAgentSchema.nullable().optional(),
    min_morgi_to_open_connection: z.number().nullable().optional(),
    id: z.number(),
    first_name: z.string().optional(),
    last_name: z.string().optional(),
    birth_date: z.string().optional(),
    is_converter: z.boolean(),
    untaxed_morgi_balance: z.number(),
    untaxed_micro_morgi_balance: z.number(),
    untaxed_withdrawal_balance: z.number(),
    morgi_balance: z.number(),
    micro_morgi_balance: z.number(),
    withdrawal_balance: z.number(),
    path_changes_count: z.number(),
    first_micromorgi_gift_leaders: z.number(),
    revenue_share: z.union([z.string(), z.number()]),
    read_must_dos: z.boolean(),
    has_thank_yous: z.boolean(),
    participated_to_course: z.boolean(),
    is_special: z.boolean().optional(),
    special_type: z.string().nullable().optional(),
    is_visible: z.boolean(),
    limit_approach_per_day: z.number(),
    today_approaches: z.number(),
    phone_number_country: z.string().nullable().optional(),
    phone_number: z.string().nullable().optional(),
    has_past_goals: z.boolean(),
    payment_for_first_connection: z.boolean().nullable().optional(),
    goals: z.array(
        z.object({
            id: z.number(),
            name: z.string(),
            slug: z.string(),
            details: z.string(),
            target_amount: z.string(),
            currency_type: z.string(),
            rookie_id: z.number(),
            start_date: z.string(),
            end_date: z.string(),
            thank_you_message: z.string(),
            cancelled_at: z.string().datetime().nullable(),
            cancelled_reason: z.string().nullable(),
            type_id: z.number(),
            status: z.string(),
            proof_note: z.string(),
            created_at: z.string(),
            has_image_proof: z.boolean(),
            has_video_proof: z.boolean(),
            broadcast: z
                .object({
                    id: z.number(),
                    sender_id: z.number(),
                    representative_id: z.number().nullable(),
                    created_at: z.string(),
                    updated_at: z.string(),
                    is_goal: z.boolean(),
                    display_name: z.string(),
                    pivot: z.object({
                        goal_id: z.number(),
                        broadcast_id: z.number()
                    })
                })
                .nullable(),
            is_for_others: z.boolean(),
            radiobox: z.union([z.string(), z.null()]),
            leader_donations_amount: z.number(),
            is_saved: z.boolean(),
            media: z
                .array(
                    z.object({
                        id: z.number(),
                        goal_id: z.number(),
                        type: z.string(),
                        is_main: z.boolean(),
                        path_location: z.string(),
                        url: z.string()
                    })
                )
                .nullable(),
            proofs: z
                .array(
                    z.object({
                        id: z.number(),
                        goal_id: z.number(),
                        type: z.string(),
                        path_location: z.string(),
                        status: z.string(),
                        created_at: z.string(),
                        updated_at: z.string(),
                        declined_reason: z.string().nullable(),
                        admin_id: z.number().nullable(),
                        url: z.string()
                    })
                )
                .nullable(),
            type: z.object({
                id: z.number(),
                type: z.string(),
                min: z.number(),
                max: z.number(),
                currency_type: z.string(),
                duration_type: z.string(),
                duration_value: z.number(),
                is_active: z.number()
            }),
            donations_count: z.union([z.string(), z.number()]),
            rookie: z.object({
                id: z.number(),
                avatar: z
                    .object({
                        id: z.number(),
                        user_id: z.number(),
                        path_location: z.string(),
                        main: z.boolean(),
                        is_face_recognition_processed: z.boolean(),
                        title: z.string().nullable(),
                        content: z.string().nullable(),
                        tags: z.string().nullable(),
                        created_at: z.string(),
                        updated_at: z.string(),
                        url: z.string(),
                        under_validation: z.boolean()
                    })
                    .nullable(),
                gender: GenderSchema.nullable(),
                username: z.string(),
                full_name: z.string()
            }),
            goal_group: z
                .object({
                    id: z.number(),
                    user_id: z.number(),
                    key_name: z.string(),
                    goal_id: z.number(),
                    created_at: z.string(),
                    updated_at: z.string()
                })
                .nullable()
        })
    ),
    photos_count: z.number(),
    photos: z.array(
        z.object({
            id: z.number(),
            user_id: z.number(),
            url: z.string().nullable(),
            path_location: z.string().nullable(),
            main: z.boolean(),
            created_at: z.string(),
            under_validation: z.boolean()
        })
    ),
    videos: z.array(
        z.object({
            id: z.number(),
            user_id: z.number(),
            blurred_url: z.string().nullable(),
            blurred_path_location: z.string().nullable(),
            is_processed: z.number().optional(),
            created_at: z.string(),
            under_validation: z.boolean(),
            url: z.string().nullable(),
            path_location: z.string().nullable()
        })
    ),
    videos_count: z.number(),
    country: CountrySchema,
    region: z.object({id: z.number().optional(), name: z.string()}).nullable(),
    city: z.object({id: z.number(), name: z.string(), country_id: z.number()}).nullable(),
    path: z
        .object({
            id: z.number(),
            name: z.string(),
            key_name: z.string(),
            is_subpath: z.boolean(),
            prepend: z.string()
        })
        .nullable(),
    subpath: z.string().nullable().optional(),
    is_winner: z.boolean().nullable(),
    converter_request: z.null(),
    featured_leaders: FeaturesGuestsSchema,
    referral_message: z
        .object({
            id: z.number(),
            user_id: z.number(),
            message: z.string()
        })
        .nullable(),
    has_representative: z.boolean(),
    mega_gifts_count: z.number(),
    top_fans_infos: z
        .object({
            completed_counter: z.number(),
            min_amount_to_reach: z.number(),
            pending_counter: z.number(),
            group_id: z.number()
        })
        .optional(),
    expertise: z
        .object({
            id: z.number(),
            key_name: z.string(),
            category: z.string(),
            name: z.string()
        })
        .nullable()
        .optional(),
    surprises_count: z.number().optional(),
    subscription_package: z
        .object({
            id: z.number(),
            amount: z.number(),
            dollar_amount: z.number(),
            surprises_count: z.number(),
            is_default: z.boolean(),
            is_visible: z.boolean()
        })
        .nullable()
        .optional(),
    leader_surprises_seen_count: z.number().optional()
})

export const IdVerifiedSchema = z.union([
    z.object({
        card_id_status: z.nativeEnum(IdCardStatusesE),
        card_id_reason: z.string().nullable(),
        front: z.object({
            status: z.nativeEnum(IdCardStatusesE),
            url: z.string(),
            path_location: z.string(),
            reason: z.string().nullable()
        }),
        back: z.object({
            status: z.nativeEnum(IdCardStatusesE),
            url: z.string(),
            path_location: z.string(),
            reason: z.string().nullable()
        }),
        selfie: z.object({
            status: z.nativeEnum(IdCardStatusesE),
            url: z.string(),
            path_location: z.string(),
            reason: z.string().nullable()
        })
    }),
    z.null()
])

export const GeneralUserSchema = z.object({
    id: z.number(),
    signup_source: z.enum(['morgi', 'google', 'facebook']),
    status: UserStatuses,
    type: z.nativeEnum(UserRoleE),
    full_name: z.string(),
    username: z.string(),
    description: z.union([z.string(), z.null()]),
    email: z.string(),
    sent_first_message: z.boolean(),
    updated_username: z.union([z.string(), z.number()]),
    telegram_bot_token: z.string(),
    language: z.string().min(2),
    currency: z.string().min(3),
    referral_code: z.string(),
    persona: z.union([z.enum(['1', '2', '3', '4', '5', '6', '7']), z.null()]),
    advertisement_id: z.union([z.string(), z.null()]),
    joined_telegram_bot_at: z.union([z.string(), z.null()]),
    telegram_chat_id: z.union([z.number(), z.null()]),
    referred_by: z.union([z.string(), z.number(), z.null()]),
    is_online: z.boolean(),
    total_subscriptions_count: z.number(),
    total_successful_paused_connections: z.number(),
    total_paused_connections: z.number(),
    pubnub_uuid: z.string().uuid(),
    created_at: z.string(),
    min_morgi_to_open_connection: z.number().optional(),
    has_new_card: z.boolean().optional(),
    profile_alerts: z.array(z.object({code: z.nativeEnum(AlertTypeE), message: z.string()}).optional()),
    id_verified: IdVerifiedSchema,
    preferred_payment_method: z
        .object({
            platform: z.nativeEnum(CreditCardTypeE),
            system_name: z.string(),
            gateway_type: z.string(),
            gateway_hash: z.string(),
            last4: z.union([z.number().nullable(), z.string().nullable()])
        })
        .nullable()
        .optional(),
    treatcoin_id: z.union([z.number(), z.null()]),
    chat_is_online: z.boolean(),
    has_verified_id: z.boolean(),
    spoken_languages: z.array(SpokenLanguagesSchema),
    gender: GenderSchema.nullable(),
    avatar: z
        .object({
            id: z.number(),
            created_at: z.string(),
            main: z.boolean(),
            path_location: z.string(),
            under_validation: z.boolean(),
            url: z.string().url(),
            user_id: z.number()
        })
        .nullable(),
    video: z
        .object({
            id: z.number(),
            user_id: z.number(),
            url: z.string(),
            path_location: z.string(),
            is_processed: z.number().optional(),
            created_at: z.string(),
            under_validation: z.boolean()
        })
        .nullable()
        .optional(),
    group: z.object({
        id: z.number(),
        name: z.string()
    }),
    signup_country: CountrySchema,
    pubnub_groups: z.array(
        z.object({
            id: z.number(),
            name: z.string(),
            user_id: z.number()
        })
    ),
    chat_topics: z.array(ChatTopicsSchema),
    favorite_activities: z.array(FavoriteActivitiesSchema),
    //TODO fix when user object will be fix
    socials: z.array(SocialsSchema)
    // socials: z.array(z.object({}))
})

export const LoginPayloadSchema = z.object({
    email: z.string(),
    password: z.string()
})

export const HostSchema = GeneralUserSchema.extend({
    type_attributes: HostTypeAttributesScheme
})

export const GuestSchema = GeneralUserSchema.extend({
    type_attributes: GuestTypeAttributesSchema,
    nickname: z
        .array(
            z.object({
                id: z.number(),
                nickname: z.string(),
                nicknamer_id: z.number(),
                nicknamed_id: z.number(),
                created_at: z.string(),
                updated_at: z.string()
            })
        )
        .nullable()
        .optional()
})

export const GuestProfileExtendedSchema = GuestSchema.extend({
    micro_morgi_given: z.number(),
    morgi_given: z.number()
})

export const RepresentativeTypeAttributesSchema = z
    .object({
        agent: HostsAgentSchema.nullable().optional(),
        agent_id: z.number().nullable(),
        email: z.string().nullable(),
        first_name: z.string(),
        id: z.number(),
        invitation_id: z.number(),
        last_name: z.string(),
        phone_number: z.string(),
        phone_number_country: z.string().nullable()
    })
    .extend({
        modes: z.array(z.object({id: z.number(), key_name: z.string()})),
        rookies: z.array(HostSchema.deepPartial())
    })

export const AgentTypeAttributesSchema = z.object({
    email: z.string().nullable(),
    first_name: z.string(),
    id: z.number(),
    invitation_id: z.number(),
    last_name: z.string(),
    phone_number: z.string(),
    phone_number_country: z.string().nullable()
})

export const RepresentativeSchema = GeneralUserSchema.extend({
    type_attributes: RepresentativeTypeAttributesSchema
})
export const AgentSchema = GeneralUserSchema.extend({
    type_attributes: AgentTypeAttributesSchema
})

export const UserMeSchema = z.discriminatedUnion('userType', [
    z.object({userType: z.literal(UserRoleE.Host), data: HostSchema}),
    z.object({userType: z.literal(UserRoleE.Guest), data: GuestSchema}),
    z.object({
        userType: z.literal(UserRoleE.Representative),
        data: RepresentativeSchema
    }),
    z.object({
        userType: z.literal(UserRoleE.Agent),
        data: AgentSchema
    })
])

export const UserSchema = z.union([GuestSchema, HostSchema, RepresentativeSchema, AgentSchema])

export const LoginSocialUserBodySchema = z.object({
    access_token: z.string().optional(),
    token_type: z.string().optional(),
    expires_in: z.number().optional(),
    scope: z.string().optional(),
    authuser: z.string().optional(),
    prompt: z.string().optional(),
    first_rookie: z.string().nullable().optional(),
    public_group: z.number().optional(),
    referral_code: z.string().nullable().optional(),
    path_id: z.string().nullable().optional(),
    persona: z.string().nullable().optional(),
    goal_id: z.string().nullable().optional(),
    tc_id: z.string().nullable().optional()
})

export const AccessTokenSchema = z.object({
    access_token: z.string(),
    token_type: z.string(),
    expires_at: z.string(), // Change this to the appropriate date type if needed
    expires_in_minutes: z.number(),
    is_first_login: z.boolean(),
    user: GuestSchema
})

export const GenderResponseSchema = z.array(GenderSchema)

export const HostPartialSchema = HostSchema.deepPartial()
export const GuestPartialSchema = GuestSchema.deepPartial()

export const AttachSocialUserSchema = z.object({
    userID: z.string(),
    password: z.string(),
    platform: z.nativeEnum(SocialPlatformTypeE),
    data: GuestPartialSchema
})

export const RepresentedUserSchema = z.object({
    id: z.number(),
    username: z.string(),
    pubnubGroups: z.array(
        z.object({
            id: z.number(),
            name: z.string(),
            userId: z.number()
        })
    )
})
