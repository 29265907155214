import axios from '@/clientAPI.ts'
import {RepresentativeType, User} from '@/features/authentication/types'
import axiosRefactor from '@/refactorClientApi'
import {InvitationAgentAndRepsResponseType} from '../types'
import {InvitationAgentAndRepsResponseSchema} from './representative.schema'
import {InvitationAgentType} from '@/features/agent/types'

export const REPRESENTATIVE_API = {
    sentRepresentativeInvitation: async ({
        dataToSend
    }: {
        dataToSend: InvitationAgentType
    }): Promise<InvitationAgentType> => {
        const url = `/agents/representatives/join`
        return await axiosRefactor.post(url, dataToSend)
    },
    getRepresentativeDetails: async ({token}: {token: string}): Promise<InvitationAgentAndRepsResponseType> => {
        const url = `/representatives/invitations/verify/${token}`
        const {data} = await axiosRefactor.get(url)
        return InvitationAgentAndRepsResponseSchema.parse(data)
    },
    // TODO add correct response type and schema when UserSchema is ready
    getRepresentatives: async (): Promise<RepresentativeType[]> => {
        const url = `/representatives`
        const {data} = await axios.get(url)
        return data
    },
    deleteRepresentative: async ({id}: {id: number}) => {
        const url = `/agents/representatives/${id}`
        return await axiosRefactor.delete(url)
    },
    updateRepresentative: async ({id, body}: {id: number; body: {rookiesId?: number[]; modesIds?: number[]}}) => {
        const url = `/agents/representatives/${id}`
        return await axiosRefactor.patch(url, body)
    },
    representativeAcceptRequest: async (token: string) => {
        const url = `/agents/representatives/requests/accept/${token}`
        return await axiosRefactor.get(url)
    },
    representativeHosts: async (): Promise<Partial<User>[]> => {
        const url = `/representatives/rookies?response_type=extended`
        const {data} = await axios.get(url)
        return data
    },
    representativeHostsChannels: async () => {
        const url = `/representatives/rookies/channels`
        const {data} = await axios.get(url)
        return data
    }
}
