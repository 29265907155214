import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from './keys'
import {REPRESENTATIVE_API} from '../services/representative.http'

export const useGetRepresentativeHostsChannels = (enabled = true) => {
    return useQuery({
        queryKey: [QUERY_KEYS.representative_hosts_channels],
        queryFn: () => REPRESENTATIVE_API.representativeHostsChannels(),
        enabled
    })
}