export const MUTATION_KEYS = {
    representative_invitation: 'representative-invitation',
    delete_representative: 'delete-representative',
    update_representative: 'update_representative'
}

export const QUERY_KEYS = {
    representative_details: 'representative-details',
    representatives: 'representatives',
    representative_accept_request: 'representative-accept-request',
    representative_hosts: 'representative-hosts',
    representative_hosts_channels: 'representative-hosts-channels'
}
