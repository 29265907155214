export const ROUTE_LOGIN_HOST = '/auth/login-host'
export const ROUTE_LOGIN_GUEST = '/auth/login-guest'
export const ROUTE_SIGNUP_HOST = '/auth/signup-host'
export const ROUTE_SIGNUP_GUEST = '/auth/signup-guest'
export const ROUTE_SIGNUP_SPECIAL_HOST = '/auth/signup-special-host/:expertise'
export const ROUTE_SIGNUP_GUEST_SOCIAL = '/auth/signup-guest-social'
export const ROUTE_SIGNUP_SUCCESS = '/auth/signup/success'
export const ROUTE_SIGNUP_ACTIVATION = '/auth/signup/activate/:token'
export const ROUTE_LINK_ACCOUNTS = '/auth/link-accounts'
export const ROUTE_COMPLETE_PROFILE = '/complete-profile'
export const ROUTE_UNSUBSCRIBE = '/unsubscribe/:token'
export const ROUTE_BLOCKED_USER = '/blocked-user'
export const ROUTE_NO_HOSTS = '/no-hosts'
export const ROUTE_CHAT = '/message-center/:channel'
export const ROUTE_MESSAGE_CENTER = '/message-center'
export const ROUTE_HOST_MY_PROFILE = '/host/my-profile'
export const ROUTE_HOST_PHOTO_UPLOAD = '/host/photo-upload'
export const ROUTE_HOST_VIDEO_UPLOAD = '/host/video-upload'
export const ROUTE_HOST_GENERAL_TIPS = '/host/general-tips'
export const ROUTE_HOST_MORGI_WINNERS = '/host/morgi-winners'
export const ROUTE_HOST_DAILY_INSPIRATION = '/host/daily-inspiration'
export const ROUTE_INVITE_FRIENDS = '/invite-friends'
export const ROUTE_HOST_PROMOTE = '/host/promote'
export const ROUTE_HOST_PAYMENT_METHOD = '/host/payment-method'
export const ROUTE_ACTIVE_GIFTING_GUESTS = '/host/active-gifting-friends'
export const ROUTE_DAILY_HOST = '/host/daily-winner'
export const ROUTE_GUEST_GIFT_HISTORY = '/host/friend-gift-history/:guestId'
export const ROUTE_HOME = '/'
export const ROUTE_SAVED_HOSTS = '/hosts/saved'
export const ROUTE_BIRTHDAYS = '/birthdays'
export const ROUTE_REFER_A_GUEST = '/refer-a-friend'
export const ROUTE_REFER_A_HOST = '/refer-a-host'
export const ROUTE_MY_MICROMORGI_BALANCE = '/micromorgi-balance'
export const ROUTE_ACTIVE_GIFTS = '/active-gifts'
export const ROUTE_RESET_PASSWORD = '/reset-password/:token'
export const ROUTE_PASSWORD_RECOVERY = '/reset-password'
export const ROUTE_CHANGE_PASSWORD = '/change-password'
export const ROUTE_EDIT_PROFILE = '/edit-profile'
export const ROUTE_TERMS_AND_CONDITIONS = '/terms&conditions'
export const ROUTE_PRIVACY_POLICY = '/privacy-policy'
export const ROUTE_HOST_SCORE = '/host/score'
export const ROUTE_DELETE_PROFILE = '/delete-account'
export const ROUTE_TRANSACTION_HISTORY = '/transaction-history'
export const ROUTE_PATH_FILTER = '/path-filter'
export const ROUTE_A_WORD_FROM_PRESIDENT = '/a-word-from-president'
export const ROUTE_OUR_MISSION = '/our-mission'
export const ROUTE_WHAT_IS_MORGI = '/what-is-morgi'
export const ROUTE_WHO_WE_ARE = '/who-we-are'
export const ROUTE_WELCOME_HOST = '/welcome'
export const ROUTE_MESSAGE_CENTER_CHAT = '/message-center/:channel?'
export const ROUTE_SECOND_CHANCE = '/second-chance'
export const ROUTE_HOST_PROFILE = '/:username'
export const ROUTE_HOST_MYSELF = '/profile'
export const ROUTE_GUEST_QUOTES = '/friend-quotes'
export const ROUTE_CHECK_PAYMENT = '/payment-status'
export const ROUTE_UPDATE_CREDIT_CARD = '/update-card'
export const ROUTE_UPDATE_RECURRING_TRANSACTION = '/update-transactions'
export const ROUTE_TELEGRAM_BOT = '/telegram-bot'
export const ROUTE_TELEGRAM_TIPS = '/tips'
export const ROUTE_COOKIE_POLICY = '/cookie-policy'
export const ROUTE_TOS_GUEST = '/tos-friend'
export const ROUTE_TOS_HOST = '/tos-host'
export const ROUTE_COMMUNITY_GUIDELINES = '/community-guidelines'
export const ROUTE_DISCORD_COMMUNITY = '/discord-community'
export const ROUTE_FAQ_HOST = '/faq-host'
export const ROUTE_FAQ_GUEST = '/faq-friend'
export const ROUTE_COUPON_HISTORY = '/coupon-history'
export const ROUTE_ON_BOARD_TOUR = '/on-board-tour'
export const ROUTE_CONNECT_TELEGRAM_PAGE = '/connect-telegram'
export const ROUTE_WELCOME_BACK_PAGE = '/welcome-back'
export const ROUTE_ADD_NEW_INFO_PAGE = '/add-new-info'
export const ROUTE_APPROACH_FIRST = '/approach'
export const ROUTE_SIGNIN_AFTER_EMAIL = '/signin-after-email'
export const ROUTE_MY_MOMENTS_FEED = '/my-moments'
export const ROUTE_TOP_FAN_GUESTSBOARD = '/top-fan-leadersboard'
export const ROUTE_WHOS_TOP_FAN_YOU_ARE = '/whos-top-fan-you-are'
export const ROUTE_UPLOAD_YOUR_SURPRISE = '/upload-your-surprise'
export const ROUTE_LOGIN_EMAIL_VERIFICATION = '/auth/login/email-verify/:token'
export const ROUTE_SIGNUP_EMAIL_VERIFICATION = '/auth/signup/email-verify/:token'

export const ROUTE_MY_GOALS = '/my-goals'
export const ROUTE_CREATE_GOAL = '/create-goal/:typeId'
export const ROUTE_GOAL = '/goal/:slug'
export const ROUTE_GOAL_PROOF = '/goal-proof/:goalId'
export const ROUTE_HOST_GOALS_TOUR = '/rookie-goal-tour/:typeId'
export const ROUTE_SUPPORTER_GUEST_PAGE = '/supporter-leader/:leaderId'
export const ROUTE_SELECT_GOAL_TYPE = '/select-goal-type'
export const ROUTE_GOALS_MAIN_PAGE = '/goals-main'
export const ROUTE_SAVED_GOALS = '/saved-goals'
export const ROUTE_SUPPORTED_GOALS = '/supported-goals'
export const ROUTE_PAST_GOALS = '/past-goals/:rookieId'
export const ROUTE_WHAT_ARE_GOALS = '/what-are-goals'
export const ROUTE_DETAILED_TRANSACTION = '/transaction/goal/:goalId'
export const ROUTE_SIGNUP_REPRESENTATIVE = '/auth/signup-representative/:token'
export const ROUTE_SIGNUP_AGENT = '/auth/signup-agent/:token'
export const ROUTE_ACCEPT_JOIN_REQUEST = '/accept-join-request/:token'
export const ROUTE_MANAGE_REPS = '/manage-reps'
export const ROUTE_MANAGE_AGENT = '/manage-agent'
export const ROUTE_PERMISSIONS = '/permissions'
export const ROUTE_EDIT_REPRESENTATIVE = '/edit-representative'

export const PUBLIC_ROUTE_WAIVER_OF_RIGHTS = '/competition-waivers'
