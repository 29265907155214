import {useTranslation} from 'react-i18next'
import {CreditCardTypeE, CreditCardsNameE, PaymentMethodUrls} from '../../types'
import paymentMethods from '@/assets/images/paymentMethods.png'
import PaymentMethodCard from '../payment-method-card/PaymentMethodCard'

const CreditCardsList = ({
    paymentMethodUrls,
    activeMethodType,
    onPaymentMethodClick
}: {
    paymentMethodUrls: PaymentMethodUrls | null
    activeMethodType?: CreditCardTypeE | null
    onPaymentMethodClick?: (type: CreditCardTypeE, url: string) => void
}) => {
    const {t} = useTranslation()

    const paymentMethodsContent = {
        [CreditCardsNameE.CCBIL_CREDIT_CARD]: {
            url: paymentMethodUrls && paymentMethodUrls[CreditCardsNameE.CCBIL_CREDIT_CARD],
            type: CreditCardTypeE.CCBILL,
            title: t('choose_payment_method:credit_card'),
            subTitle: t('choose_payment_method:processed_by_ccbill'),
            icon: paymentMethods
        },
        [CreditCardsNameE.PRAXIS_CREDIT_CARD]: {
            url: paymentMethodUrls && paymentMethodUrls[CreditCardsNameE.PRAXIS_CREDIT_CARD],
            type: CreditCardTypeE.PRAXIS,
            title: t('choose_payment_method:credit_card'),
            subTitle: t('choose_payment_method:processed_by_praxis'),
            icon: paymentMethods
        },
        [CreditCardsNameE.PRAXIS_GENERIC]: null
    }

    const paymentMethodList =
        Object.keys(paymentMethodsContent).filter(payment => {
            const methodName = payment as CreditCardsNameE
            return (
                payment !== CreditCardsNameE.PRAXIS_GENERIC &&
                paymentMethodsContent[methodName]?.type !== activeMethodType &&
                !!paymentMethodsContent[methodName]?.url
            )
        }) || []

    return paymentMethodList.map(paymentMethod => {
        const methodName = paymentMethod as CreditCardsNameE

        return methodName !== CreditCardsNameE.PRAXIS_GENERIC ? (
            <PaymentMethodCard
                external={paymentMethodsContent[methodName]?.type === CreditCardTypeE.CCBILL}
                key={paymentMethodsContent[methodName]?.url}
                onClick={() =>
                    onPaymentMethodClick?.(
                        paymentMethodsContent[methodName].type,
                        `${paymentMethodsContent[methodName].url}`
                    )
                }
                icon={paymentMethodsContent[methodName]?.icon}
                title={paymentMethodsContent[methodName]?.title}
                subTitle={paymentMethodsContent[methodName]?.subTitle}
            />
        ) : null
    })
}

export default CreditCardsList
