import {Navigate, Outlet} from 'react-router-dom'
import {REDIRECT_MAP} from '@/utilities/constants'
import {useMe} from '@/features/authentication/queries/useMe'
import {UserRoleE} from '@/types'

export const RepresentativeRoute = () => {
    const {data: user, isLoading} = useMe()
    const roleOfUser = user?.type
    const allowableUsersRole = [UserRoleE.Representative]
    const isAdmitted = roleOfUser && allowableUsersRole.includes(roleOfUser)

    return isLoading || isAdmitted ? <Outlet /> : <Navigate to={roleOfUser ? REDIRECT_MAP[roleOfUser] : '/'} />
}
